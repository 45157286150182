<template>
  <div>
    <div :v-model="taskList">
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <v-select class="select-large mt-10 w-full" :disabled="disabledFlag" name="type" v-model="taskList.type"
            v-validate="'required'" :placeholder="$t('Agent Type')" label="name" :options="types"/>
          <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select class="select-large mt-10 w-full" :disabled="disabledFlag" name="agent" v-model="taskList.assigned_to"
            v-validate="'required'" :placeholder="$t('Agent')" label="fullName" :options="agents" />
          <span class="text-danger text-sm" v-show="errors.has('agent')">{{ errors.first('agent') }}</span>
        </div>
      </div>

      <h3 class="mt-10">{{$t('Delivery Orders')}}</h3>
      <shipblu-table
        :sst="true"
        :data="shipments"
        :tableLoader="shipmentsTableLoader"
        multiple
        v-model="selected"
        name="orders"
        pagination
        :max-items="maximumItems"
        @search="handleSearchOrders"
        @keyup.enter.native="handleSearchOrders"
        search
        v-validate="'required'"
        >
        <template slot="thead">
          <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
          <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
          <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
          <shipblu-th>{{$t('Location')}}</shipblu-th>
          <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        </template>
        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <shipblu-td :data="data[indextr].trackingNumber">
              {{ data[indextr].tracking_number }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].customerName">
              {{ data[indextr].customer.full_name }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].merchantName">
              {{ data[indextr].merchant.name }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].loaction">
              {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].phone">
              {{ data[indextr].customer.phone }}
            </shipblu-td>

          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationShipmentsObj" :noOptions="true" @changePageNumber="changePageNumber($event, 'loadShipments', 'paginationShipmentsObj')" />

      <h3>{{$t('Returns')}}</h3>
      <shipblu-table
        :sst="true"
        :data="returns"
        multiple
        :tableLoader="returnsTableLoader"
        v-model="selected"
        pagination
        :max-items="maximumItems"
        @search="handleSearchReturns"
        @keyup.enter.native="handleSearchReturns"
        search
      >
        <template slot="thead">
          <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
          <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
          <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
          <shipblu-th>{{$t('Location')}}</shipblu-th>
          <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <shipblu-td :data="data[indextr].trackingNumber">
              {{ data[indextr].tracking_number }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].customerName">
              {{ data[indextr].customer.full_name }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].merchantName">
              {{ data[indextr].merchant.name }}
            </shipblu-td>
            
            <shipblu-td :data="data[indextr].loaction">
              {{ $t(data[indextr].customer.address.zone.name) }} / {{ data[indextr].customer.address.zone.city ? $t(data[indextr].customer.address.zone.city.name) : 'N/A' }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].phone">
              {{ data[indextr].customer.phone }}
            </shipblu-td>

          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationReturnsObj" :noOptions="true" @changePageNumber="changePageNumber($event, 'loadReturns', 'paginationReturnsObj')" />
      
      <div v-if="taskList.type && (taskList.type.value === 'ma' || $route.query.type === 'MA')">
        <h3>{{$t('RTOs')}}</h3>
        <shipblu-table
          :sst="true"
          :data="RTOs"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          @search="handleSearchRTO"
          @keyup.enter.native="handleSearchRTO"
          search
          :tableLoader="RTOsTableLoader"

        >
          <template slot="thead">
            <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
            <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
            <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
            <shipblu-th>{{$t('Location')}}</shipblu-th>
            <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].trackingNumber">
                {{ data[indextr].tracking_number }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].customerName">
                {{ data[indextr].customer.full_name }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].merchantName">
                {{ data[indextr].merchant.name }}
              </shipblu-td>
              
              <shipblu-td :data="data[indextr].loaction">
                {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].phone">
                {{ data[indextr].customer.phone }}
              </shipblu-td>

            </shipblu-tr>
          </template>
        </shipblu-table>
        <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationRTOsObj" :noOptions="true" @changePageNumber="changePageNumber($event, 'loadRTOs', 'paginationRTOsObj')" />
      </div>
      
      <h3>{{$t('Supplies')}}</h3>
      <shipblu-table
        :sst="true"
        :data="supplies"
        :tableLoader="suppliesTableLoader"
        multiple
        v-model="selected"
        pagination
        :max-items="maximumItems"
      >
        <template slot="thead">
          <shipblu-th>{{$t('Supplies')}}: {{$t('Quantity')}}</shipblu-th>
          <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
          <shipblu-th>{{$t('Location')}}</shipblu-th>
          <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <shipblu-td :data="data[indextr].created">
              <div v-for="item in tr.supply_request_items.filter(item => item.count > 0)" :key="item.index">
                <span v-if="item.package_size.short_code != 'OSZ'">{{ item.package_size.name.split('(')[0] }}: {{ item.count }} </span>
              </div>
            </shipblu-td>

            <shipblu-td :data="data[indextr].merchantName">
              {{ data[indextr].merchant.name }}
            </shipblu-td>
            
            <shipblu-td :data="data[indextr].loaction">
              {{ $t(data[indextr].merchant.address.zone.name) }} / {{ $t(data[indextr].merchant.address.zone.city.name) }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].phone">
              {{ data[indextr].merchant.store_phone }}
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationSuppliesObj" :noOptions="true" @changePageNumber="changePageNumber($event, 'loadSupplies', 'paginationSuppliesObj')" />

      <h3>{{$t('Cash Collection')}}</h3>
      <shipblu-table
        :sst="true"
        :data="cashCollections"
        multiple
        :tableLoader="cashCollectionsTableLoader"
        v-model="selected"
        pagination
        :max-items="maximumItems"
        @search="handleSearchCashCollections"
        @keyup.enter.native="handleSearchCashCollections"
        search
      >
        <template slot="thead">
          <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
          <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
          <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
          <shipblu-th>{{$t('Location')}}</shipblu-th>
          <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
          <shipblu-th>{{$t('Amount')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <shipblu-td :data="data[indextr].trackingNumber">
              {{ data[indextr].tracking_number }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].customerName">
              {{ data[indextr].customer.full_name }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].merchantName">
              {{ data[indextr].merchant.name }}
            </shipblu-td>
            
            <shipblu-td :data="data[indextr].loaction">
              {{ $t(data[indextr].merchant.address.zone.name) }} / {{ $t(data[indextr].merchant.address.zone.city.name) }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].phone">
              {{ data[indextr].merchant.store_phone }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].amount">
              {{ data[indextr].amount }}
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationCashCollectionsObj" :noOptions="true" @changePageNumber="changePageNumber($event, 'loadCashCollections', 'paginationCashCollectionsObj')" />
      
      <span class="text-danger text-sm" v-show="errors.has('orders')">{{ errors.first('orders') }}</span>
    </div>
    <vs-button v-if="disabledFlag" type="filled" @click="addOrders" class="float-right m-5 block">{{$t('Add Orders')}}</vs-button>
    <vs-button v-else type="filled" @click="addTaskList" class="float-right m-5 block">{{$t('Add Task List')}}</vs-button>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import ShipbluCursorPagination from '../../../layouts/components/ShipbluCursorPagination.vue'

export default {
  data () {
    return {
      selected: [],
      selectedReturns: [],
      disabledFlag: false,
      types: [
        {
          name: 'CA',
          value: 'ca'
        },
        {
          name: 'MA',
          value: 'ma'
        }
      ],
      taskList: {
        type: '',
        assigned_to: ''
      },
      agents: [],
      shipments: [],
      shipmentsTableLoader: false,
      returnsTableLoader: false,
      RTOsTableLoader: false,
      suppliesTableLoader: false,
      cashCollectionsTableLoader: false,
      returns: [],
      warehousesDic: {},
      shipmentsIds: [],
      returnsIds: [],
      agentsDic: {},
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      paginationShipmentsObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      paginationReturnsObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      paginationRTOsObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      paginationSuppliesObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      paginationCashCollectionsObj: {
        currentPage: 1, 
        next: null,
        previous: null
      },
      searchOrders: '',
      searchReturns: '',
      taskSheetOrdersIDs: [],
      taskSheetSuppliesIDs: [],
      suppliesIds: [],
      cashCollectionsIds: [],
      supplies: [],
      cashCollections: [],
      searchSupplies: '',
      searchCashCollections: '',
      RTOSearch: '',
      RTOs: [],
      RTOsIds: [],
      searchOrderInProgress: false,
      searchedOrderValue: ' ',
      searchReturnInProgress: false,
      searchedReturnValue: ' ',
      searchRTOInProgress: false,
      searchedRTOValue: ' ',
      searchCashCollectionInProgress: false,
      searchedCashCollectionValue: ' '
    }
  },
  watch: {
    'taskList.type' () {
      if (this.taskList.type) {
        this.loadAgents()
      } else {
        this.agents = []
        this.shipments = []
      }
    },
    'taskList.assigned_to' () {
      if (this.taskList.assigned_to && this.taskList.type) {
        this.loadShipments()
        this.loadReturns()
        if (this.taskList.type.value === 'ma' || this.$route.query.type === 'MA') {
          this.loadSupplies()
          this.loadRTOs()
        } else {
          this.loadCashCollections()
        }
      } else {
        this.shipments = []
      }
    }
  },
  methods: {
    changePageNumber (val, methodName, objectName) {
      this[objectName].currentPage = val === 'next' ? this[objectName].currentPage + 1 : this[objectName].currentPage - 1
      this[methodName](this[objectName][val])
    },
    loadTaskSheetOrders () {
      sendRequest(false, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/`, 'get', null, true, 
        (response) => {
          this.taskSheetOrdersIDs = []
          if (response.data.task_sheet.length > 0) {
            response.data.task_sheet.map(element => {
              if (element.order) {
                this.taskSheetOrdersIDs.push(element.order.id)
              } else if (element.cash_collection) {
                this.taskSheetOrdersIDs.push(element.cash_collection.id)
              }
            })
            response.data.task_sheet_supplies.map(element => {
              this.taskSheetSuppliesIDs.push(element.supplies_request.id)
            })
          }
        }
      )
    },
    handleSearchOrders (search) {
      this.searchOrders = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchOrderInProgress || this.searchedOrderValue === this.searchOrders) {
        return
      }
      this.loadShipments()
    },
    handleSearchReturns (search) {
      this.searchReturns = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadReturnsSearch)
    },
    loadReturnsSearch () {
      if (this.searchReturnInProgress || this.searchedReturnValue === this.searchReturns) {
        return
      }
      this.loadReturns()
    },
    handleSearchRTO (search) {
      this.RTOSearch = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadRTOsSearch)
    },
    loadRTOsSearch () {
      if (this.searchRTOInProgress || this.searchedRTOValue === this.RTOSearch) {
        return
      }
      this.loadRTOs()
    },
    handleSearchCashCollections (search) {
      this.searchCashCollections = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCashCollectionsSearch)
    },
    loadCashCollectionsSearch () {
      if (this.searchCashCollectionInProgress || this.searchedCashCollectionValue === this.searchCashCollections) {
        return
      }
      this.loadCashCollections()
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
    },
    loadAgents () {
      this.$vs.loading()
      let URl = ''
      if ((this.taskList.type && this.taskList.type.value === 'ca') || this.$route.query.type === 'CA') {
        URl = `api/v1/warehouse/${this.$route.params.warehouseID}/agents/customer_agent/?limit=100`
      } else {
        URl = `api/v1/warehouse/${this.$route.params.warehouseID}/agents/merchant_agent/?limit=100`
      }
      sendRequest(false, false, this, URl, 'get', null, true, 
        (response) => {
          this.agents = response.data.results
          this.agents.forEach(element => {
            this.agentsDic[element.id] = element
            element.fullName = `${element.first_name} ${element.last_name}`
          })
          if (this.$route.query.type) {
            this.taskList.assigned_to = this.agentsDic[this.$route.query.agent]
          }
        }
      )
    },
    loadShipments (cursorVal) {
      if (!cursorVal) this.paginationShipmentsObj.currentPage = 1
      this.searchOrderInProgress = true
      this.$vs.loading()
      let query = ''
      if ((this.taskList.type && (this.taskList.type.value === 'ca')) || this.$route.query.type === 'CA') {
        query = `?limit=${this.maximumItems}&cursor=${cursorVal ? cursorVal : ''}&search=${this.searchOrders}&status=in_transit&current_warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&task_sheet=true&rto_requested=false&on_hold=false`
      } else {
        query = `?limit=${this.maximumItems}&cursor=${cursorVal ? cursorVal : ''}&search=${this.searchOrders}&status=pickup_requested,out_for_pickup&merchant_zone_group=${this.taskList.assigned_to && this.taskList.assigned_to.zone_group ? this.taskList.assigned_to.zone_group.id : ''}&task_sheet=true&rto_requested=false&is_counter_dropoff=false&on_hold=false`
      }
      const url = 'api/v1/delivery-orders/'
      this.shipmentsTableLoader = true
      sendRequest(true, false, this, `${url}${query}`, 'get', null, true, 
        (response) => {
          this.shipments = response.data.results
          this.paginationShipmentsObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationShipmentsObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.shipmentsTableLoader = false
          this.shipments.forEach(element => {
            this.shipmentsIds.push(element.id)
          })
          this.searchedOrderValue = this.searchOrders
        }
      )
      this.searchOrderInProgress = false
    },
    loadReturns (cursorVal) {
      if (!cursorVal) this.paginationReturnsObj.currentPage = 1
      this.searchReturnInProgress = true
      this.$vs.loading()
      let query = ''
      if ((this.taskList.type && this.taskList.type.value === 'ca') || this.$route.query.type === 'CA') {
        query = `?limit=${this.maximumItems}&cursor=${cursorVal ? cursorVal : ''}&search=${this.searchReturns}&status=return_requested&inbound_warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&cash_amount=0`
      } else {
        query = `?limit=${this.maximumItems}&cursor=${cursorVal ? cursorVal : ''}&search=${this.searchReturns}&status=in_transit&current_warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&merchant_zone_group=${this.taskList.assigned_to && this.taskList.assigned_to.zone_group ? this.taskList.assigned_to.zone_group.id : ''}`
      }
      const url = 'api/v1/returns/'
      this.returnsTableLoader = true
      sendRequest(true, false, this, `${url}${query}`, 'get', null, true, 
        (response) => {
          this.returns = response.data.results
          this.paginationReturnsObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationReturnsObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.returnsTableLoader = false
          this.returns.forEach(element => {
            this.returnsIds.push(element.id)
          })
          this.searchedReturnValue = this.searchReturns
        }
      )
      this.searchReturnInProgress = false
    },
    loadSupplies (cursorVal) {
      if (!cursorVal) this.paginationSuppliesObj.currentPage = 1
      let query = ''
      if (this.taskList.type.value === 'ma' || this.$route.query.type === 'MA') {
        query = `?limit=${this.maximumItems}&cursor=${cursorVal ? cursorVal : ''}&warehouse=${this.$route.params.warehouseID}&status=created,requested&merchant_zone_group=${this.taskList.assigned_to && this.taskList.assigned_to.zone_group ? this.taskList.assigned_to.zone_group.id : ''}`
      }
      const url = 'api/v1/supplies/'
      this.suppliesTableLoader = true
      sendRequest(true, false, this, `${url}${query}`, 'get', null, true, 
        (response) => {
          this.supplies = response.data.results
          this.suppliesTableLoader = false
          this.paginationSuppliesObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationSuppliesObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.supplies.forEach(element => {
            this.suppliesIds.push(element.id)
          })
        }
      )
    },
    loadCashCollections (cursorVal) {
      if (!cursorVal) this.paginationCashCollectionsObj.currentPage = 1
      this.searchCashCollectionInProgress = true
      const query = `?limit=${this.maximumItems}&cursor=${cursorVal ? cursorVal : ''}&search=${this.searchCashCollections}&warehouse=${this.$route.params.warehouseID}&status=collection_requested`
      const url = 'api/v1/cash-collections/'
      this.cashCollectionsTableLoader = true
      sendRequest(true, false, this, `${url}${query}`, 'get', null, true, 
        (response) => {
          this.cashCollections = response.data.results
          this.paginationCashCollectionsObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationCashCollectionsObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.cashCollectionsTableLoader = false
          this.cashCollections.forEach(element => {
            this.cashCollectionsIds.push(element.id)
          })
          this.searchedCashCollectionValue = this.searchCashCollections
        }
      )
      this.searchCashCollectionInProgress = false
    },
    loadRTOs (cursorVal) {
      if (!cursorVal) this.paginationRTOsObj.currentPage = 1
      this.RTOsTableLoader = true
      this.searchRTOInProgress = true
      const query = `?limit=${this.maximumItems}&cursor=${cursorVal ? cursorVal : ''}&search=${this.RTOSearch}&current_warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&rto_requested=true&merchant_zone_group=${this.taskList.assigned_to && this.taskList.assigned_to.zone_group ? this.taskList.assigned_to.zone_group.id : ''}&task_sheet=true&status=in_transit`
      sendRequest(true, false, this, `api/v1/delivery-orders/${query}`, 'get', null, true, 
        (response) => {
          this.RTOs = response.data.results
          this.paginationRTOsObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationRTOsObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.RTOsTableLoader = false
          this.RTOs.forEach(element => {
            this.RTOsIds.push(element.id)
          })
          this.searchedRTOValue = this.RTOSearch
        }
      )
      this.searchRTOInProgress = false
    },
    addTaskList () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()

          const addTaskSheet = {
            orders: [],
            returns: [],
            supplies_requests: [],
            cash_collections: []
          }
          const selectedIds = []
          this.selected.forEach(element => {
            if (!selectedIds.includes(element.id)) {
              selectedIds.push(element.id)
            }
          })
          selectedIds.forEach(element => {
            if (this.shipmentsIds.includes(element) || this.RTOsIds.includes(element)) {
              addTaskSheet.orders.push(element)
            } else if (this.suppliesIds.includes(element)) {
              addTaskSheet.supplies_requests.push(element)
            } else if (this.cashCollectionsIds.includes(element)) {
              addTaskSheet.cash_collections.push(element)
            } else {
              addTaskSheet.returns.push(element)
            }
          })
          if (this.taskList.type.value === 'ca') {
            addTaskSheet.customer_agent = this.taskList.assigned_to.id
          } else {
            addTaskSheet.merchant_agent = this.taskList.assigned_to.id
          }
          sendRequest(false, false, this, 'api/v1/task-sheets/', 'post', addTaskSheet, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Task List'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.$router.push({
                name: 'head-of-fleet-task-lists',
                params: {
                  warehouseID: this.$route.params.warehouseID
                }
              }).catch(() => {})
            }
          )
        }
      })
    },
    addOrders () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          const taskSheetsOrders = []
          const taskSheetsSupplies = []
          const taskSheetsCashCollections = []
          const selectedIds = []
          this.selected.forEach(element => {
            if (!selectedIds.includes(element.id)) {
              selectedIds.push(element.id)
            }
          })
          const sequence = Number(this.$route.query.sequence) + 1
          selectedIds.forEach((element, index) => {
            if (!this.taskSheetOrdersIDs.includes(element) && (this.shipmentsIds.includes(element) || this.returnsIds.includes(element) || this.RTOsIds.includes(element))) {
              taskSheetsOrders.push({
                order: element,
                sequence: Number(sequence) + Number(index)
              })
            } else if (this.suppliesIds.includes(element) && !this.taskSheetSuppliesIDs.includes(element)) {
              taskSheetsSupplies.push({
                supplies_request: element,
                sequence: Number(sequence) + Number(index)
              })
            } else if (this.cashCollectionsIds.includes(element) && !this.taskSheetOrdersIDs.includes(element)) {
              taskSheetsCashCollections.push({
                cash_collection: element,
                sequence: Number(sequence) + Number(index)
              })
            }
          })
          sendRequest(false, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/orders/`, 'post', taskSheetsOrders, true,
            () => {
              this.addSupplies(taskSheetsSupplies, taskSheetsCashCollections)
            }
          )
        }
      })
    },
    addSupplies (supplies, cashCollections) {
      sendRequest(false, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/supplies-requests/`, 'post', supplies, true,
        () => {
          this.addCashCollections(cashCollections)
        }
      ) 
    },
    addCashCollections (cashCollections) {
      sendRequest(false, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/cash-collections/`, 'post', cashCollections, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Orders'] + i18nData[this.$i18n.locale][' has been added to task sheet successfully!'],
            position: 'top-center'
          })
          this.$router.push({
            name: 'head-of-fleet-task-list',
            params: {
              warehouseID: this.$route.params.warehouseID,
              taskListID: this.$route.params.taskListID
            }
          }).catch(() => {})
        }
      ) 
    }
  },
  components: {
    vSelect,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    ShipbluCursorPagination
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
    if (this.$route.query.type) {
      this.loadTaskSheetOrders()
      this.taskList.type = this.$route.query.type
      this.disabledFlag = true
    } else {
      this.taskList = {}
      this.disabledFlag = false
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>