<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2">
        <h2>{{$t('Merchants')}}</h2>
      </div>
    </div>
    <div class="mt-6 float-left clearfix">
      <table-filter :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
    </div>
    
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="merchants"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>

      <template slot="thead">
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Account Number')}}</shipblu-th>
        <shipblu-th>{{$t('Store URL')}}</shipblu-th>
        <shipblu-th>{{$t('CoD Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Wallet Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Service fees & Refunds due')}}</shipblu-th>
        <shipblu-th>
          <div class="flex items-center gap-2">
            <p class="whitespace-no-wrap">{{$t('Net cash balance')}}</p>
            <vx-tooltip position="top" color="dark" :text="`${$t('Net cassh balance')} = ${$t('CoD balance')} - ${$t('Service fees & Refunds due')}`">
              <span class="material-symbols-outlined flex text-base cursor-pointer">info</span>
            </vx-tooltip>
          </div>
        </shipblu-th>
        <shipblu-th>{{$t('Quickbooks last updated')}}</shipblu-th>
        <shipblu-th>{{$t('Store Phone')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-merchant-view`, params: { merchantID: data[indextr].id, tab: 'info' }}">
          <shipblu-td :data="data[indextr].name">
            {{ (data[indextr].name !== data[indextr].display_name && data[indextr].display_name !== null) ? data[indextr].name + ' - ' + data[indextr].display_name : data[indextr].name}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].id">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].store_url">
            {{ data[indextr].store_url }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].codBalance">
            {{ Number(data[indextr].quickbooks_cod_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].walletBalance">
            {{ Number(data[indextr].quickbooks_wallet_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].fees">
            {{ Number(data[indextr].quickbooks_customer_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].netCash">
            {{ Number(data[indextr].quickbooks_cod_balance - data[indextr].quickbooks_customer_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].fees">
            {{ data[indextr].quickbooks_last_updated ? new Date(data[indextr].quickbooks_last_updated).toLocaleDateString('fr-CA') + ' ' + new Date(data[indextr].quickbooks_last_updated).toLocaleTimeString('en-GB') : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].store_phone">
            {{ data[indextr].store_phone }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ data[indextr].address.zone ? $t(data[indextr].address.zone.name) : 'N/A' }} / {{ data[indextr].address.zone ? data[indextr].address.zone.city ? $t(data[indextr].address.zone.city.name) : 'N/A' : 'N/A' }}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOpwtions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import TableFilter from '../../views/merchant/components/TableFilter.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      merchantURL: 'api/v1/merchants/',
      selected: [],
      merchants: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' ',
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'Negative COD Balance',
          data: 'cod_balance__lt=0',
          value: 'negative_cod_balance'
        },
        {
          name: 'Negative Wallet Balance',
          data: 'wallet_balance__lt=0',
          value: 'negative_wallet_balance'
        },
        {
          name: 'Negative Net Cash Balance',
          data: 'net_cash_balance=negative',
          value: 'negative_net_cash_balance'
        }
      ],
      filtersDic: {'all': '', 'negative_wallet_balance': 'wallet_balance__lt=0', 'negative_cod_balance': 'cod_balance__lt=0', 'negative_net_cash_balance': 'net_cash_balance=negative'},
      filters: [],
      filterValue: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: 'finance-accounts-merchants',
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadMerchants()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadMerchants()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantsSearch)
    },
    loadMerchantsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadMerchants()
    },
    loadMerchants () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&name=${this.searchVal}&${this.filtersDic[this.filters]}`
      sendRequest(true, false, this, `${this.merchantURL}${query}`, 'get', null, true,
        (response) => {
          this.merchants = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    viewMerchant (data) {
      this.$router.push(
        {
          name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
          params: {
            merchantID: data.id,
            tab: 'info'
          }
        }
      ).catch(() => {})
    },
    changeFilter () {
      this.filters = []
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadMerchants()
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    TableFilter
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadMerchants()
  }
}
</script>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>
