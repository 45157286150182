<template>
  <div class="shipments-page">
    <vs-tabs v-model="activeParentTab" :class="`tabs-shadow-none ${localStorage.getItem('PrimaryWarehouse') ? '' : 'hide-tabs'}`" id="profile-tabs">
      <vs-tab :label="$t('In-House Orders')">
        <div class="my-6">
          <h2 v-if="activeTab == 0"> {{$t('Delivery Orders')}}</h2>
          <h2 v-else-if="activeTab == 1">{{$t('Returns')}}</h2>
          <h2 v-else>{{$t('Counter Returns')}}</h2>
        </div>
        
        <div class="flex items-center gap-4 mb-6">
          <div class="flex items-center gap-2 page-title p-4">
            <div :class="$route.query.tab === tab ? 'bg-primary text-white' : ''" class="py-2 px-4 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
              v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
              <div class="text-sm">{{$t(tab)}}</div>
            </div>
          </div>

          <div v-if="activeTab == 2">
            <div class="flex items-center gap-2 page-title p-4">
              <vs-button class="py-2 px-4" color="primary" @click="printTaskList">{{$t('Print Returns')}}</vs-button>
              <merchants-button class="py-2 px-4 cursor-pointer rounded-md" :selected="returnsSelected" @merchantFilter="merchantFilter = $event"></merchants-button>
              <vs-button class="py-2 px-4" color="primary" @click="confirmReturns">{{$t('Confirm')}}</vs-button>
            </div>
          </div>
        </div>

        <manager-orders v-if="activeTab == 0"></manager-orders>

        <template v-else-if="activeTab == 1">
          <h3>{{$t('In Transit')}}</h3>
          <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
            <print-label-button class="float-left" :selected="inTransitSelected"></print-label-button>
          </div>

          <shipblu-table
            :sst="true"
            orders
            multiple
            v-model="inTransitSelected"
            :max-items="maximumItems"
            @search="handleSearch"
            @keyup.enter.native="handleSearch"
            search
            pagination
            :tableLoader="returnsTableLoader"
            :data="returnsOrders"
          >
            <template slot="thead">
              <shipblu-th>{{$t('Created On')}}</shipblu-th>
              <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
              <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
              <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
              <shipblu-th>{{$t('Customer Address')}}</shipblu-th>
              <shipblu-th>{{$t('Packages')}}</shipblu-th>
              <shipblu-th>{{$t('Package Size')}}</shipblu-th>
              <shipblu-th>{{$t('Order Type')}}</shipblu-th>
              <shipblu-th>{{$t('Slot Location')}}</shipblu-th>
              <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
              <shipblu-th>{{$t('Scheduled Delivery Date')}}</shipblu-th>
              <shipblu-th>{{$t('Status')}}</shipblu-th>
            </template>

            <template slot-scope="{ data }">
              <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
                <shipblu-td :data="data[indextr].createdOn">
                  {{ common.splitYearOfDate(data[indextr].created) }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].requestNumber" class="inactive-link">
                  <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
                </shipblu-td>

                <shipblu-td :data="data[indextr].merchant">
                  {{ data[indextr].merchant.name }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].customerInfo" v-if="data[indextr].customer">
                  {{ data[indextr].customer.full_name }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].address">
                  {{ data[indextr].customer.address.line_1 }} / {{ data[indextr].customer.address.line_2 }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].packages">
                  {{ data[indextr].packages ? data[indextr].packages.length : 'N/A' }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].packages">
                  <p v-for="packageItem in data[indextr].packages" :key="packageItem.index">
                    {{packageItem.package.package_size.short_code}}
                  </p>
                </shipblu-td>

                <shipblu-td :data="data[indextr].orderType">
                  {{data[indextr].is_exchange ? $t('Exchange') : data[indextr].tracking_number[0] == 1 ? $t('Delivery') : $t('Return')}}
                </shipblu-td>

                <shipblu-td :data="data[indextr].slot">
                  <span v-for="packageItem in data[indextr].packages" :key="packageItem.index">
                    {{ packageItem.package_location.aisle_sign ? packageItem.package_location.aisle_sign : packageItem.package_location.code }} <br/>
                  </span>
                </shipblu-td>

                <shipblu-td :data="data[indextr].pickupDate">
                  {{ data[indextr].pickup_date ? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : ''}}
                </shipblu-td>

                <shipblu-td :data="data[indextr].scheduledDeliveryDate">
                  {{ data[indextr].estimated_date ? data[indextr].estimated_date : ''}}
                </shipblu-td>

                <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
                  <div class="m-auto flex items-center justify-center font-semibold">
                    <div><span class="status-icon mr-1"></span></div>
                    <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                  </div>
                </shipblu-td>
              </shipblu-tr>
            </template>
          </shipblu-table>
          <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationInTransitObj" :noOptions="true" @changePageNumber="changeInTransitPageNumber($event)" />
          
          <h2>Return Requested</h2>
          <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
            <print-label-button class="float-left" :selected="returnRequestedSelected"></print-label-button>
          </div>

          <shipblu-table
            :sst="true"
            orders
            multiple
            v-model="returnRequestedSelected"
            :max-items="maximumItems"
            @search="searchReturnPickup"
            @keyup.enter.native="searchReturnPickup"
            search
            pagination
            :data="returnsPickups"
            :tableLoader="returnsPickupsTableLoader"
          >
            <template slot="thead">
              <shipblu-th>{{$t('Created On')}}</shipblu-th>
              <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
              <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
              <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
              <shipblu-th>{{$t('Customer Address')}}</shipblu-th>
              <shipblu-th>{{$t('Packages')}}</shipblu-th>
              <shipblu-th>{{$t('Package Size')}}</shipblu-th>
              <shipblu-th>{{$t('Order Type')}}</shipblu-th>
              <shipblu-th>{{$t('Slot Location')}}</shipblu-th>
              <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
              <shipblu-th>{{$t('Scheduled Delivery Date')}}</shipblu-th>
              <shipblu-th>{{$t('Status')}}</shipblu-th>
            </template>

            <template slot-scope="{ data }">
              <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
                <shipblu-td :data="data[indextr].createdOn">
                  {{ common.splitYearOfDate(data[indextr].created) }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].requestNumber">
                  {{ data[indextr].tracking_number }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].merchant">
                  {{ data[indextr].merchant.name }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].customerInfo" v-if="data[indextr].customer">
                  {{ data[indextr].customer.full_name }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].location">
                  {{ data[indextr].customer.address.line_1 }} / {{ data[indextr].customer.address.line_2 }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].packages">
                  {{ data[indextr].packages.length }}
                </shipblu-td>

                <shipblu-td :data="data[indextr].packages">
                  <p v-for="packageItem in data[indextr].packages" :key="packageItem.index">
                    {{packageItem.package.package_size.short_code}}
                  </p>
                </shipblu-td>

                <shipblu-td :data="data[indextr].orderType">
                  {{data[indextr].is_exchange ? $t('Exchange') : data[indextr].tracking_number[0] == 1 ? $t('Delivery') : $t('Return')}}
                </shipblu-td>

                <shipblu-td :data="data[indextr].slot">
                  <span v-for="packageItem in data[indextr].packages" :key="packageItem.index">
                    {{ packageItem.package_location.aisle_sign ? packageItem.package_location.aisle_sign : packageItem.package_location.code }} <br/>
                  </span>
                </shipblu-td>

                <shipblu-td :data="data[indextr].pickupDate">
                  {{ data[indextr].pickup_date ? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : ''}}
                </shipblu-td>

                <shipblu-td :data="data[indextr].scheduledDeliveryDate">
                  {{ data[indextr].estimated_date}}
                </shipblu-td>

                <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
                  <div class="m-auto flex items-center justify-center font-semibold">
                    <div><span class="status-icon mr-1"></span></div>
                    <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                  </div>
                </shipblu-td>
              </shipblu-tr>
            </template>
          </shipblu-table>
          <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationPickupObj" :noOptions="true" @changePageNumber="changePickupPageNumber($event)" />
        </template>

        <counter-returns v-if="activeTab == 2" @returnsSelected="returnsSelected = $event" :merchantFilter="merchantFilter"></counter-returns>
      </vs-tab>

      <vs-tab :label="$t('Cycle Count')" v-if="localStorage.getItem('PrimaryWarehouse')">
        <cycle-count :activeTab="activeTab" @changeTabFilter="changeTabFilter($event)"/>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import ManagerOrders from '../manager/ManagerOrders.vue'
import HeadComponent from '../merchant/components/Header.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import MerchantsButton from './components/MerchantsButton.vue'
import CounterReturns from './components/CounterReturns.vue'
import i18nData from '../../i18n/i18nData.js'
import CycleCount from '../headOfFleet/CycleCount.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'

export default {
  data () {
    return {
      merchantFilter: '',
      taskSheet: {
        merchant_agent: {}
      },
      returnsSelected: [],
      merchantOrders: [],
      common,
      userRole: this.$store.state.AppActiveUser.userRole,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      tabs: ['Delivery Orders', 'Returns', 'Counter Returns'],
      tabsDic: {'0': 'Delivery Orders', '1': 'Returns', '2': 'Counter Returns', '3': 'Not Scanned', '4': 'Scanned'},
      tabsNameDic: {'Delivery Orders': 0, 'Returns': 1, 'Counter Returns': 2, 'Not Scanned': 3, 'Scanned': 4},
      activeTab: 0,
      inTransitSelected: [],
      returnRequestedSelected: [],
      returnsOrders: [],
      filter: '',
      paginationInTransitObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      paginationPickupObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      warehousesDic: {},
      searchVal: '',
      returnsPickups: [],
      returnsPickupsTableLoader: false,
      returnsTableLoader: false,
      searchReturnValPickup: '',
      searchInProgress: false,
      searchedValue: ' ',
      searchReturnPickupInProgress: false,
      searchedReturnPickupValue: ' ',
      localStorage,
      activeParentTab: 0

    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      if (this.activeParentTab !== 1) this.loadWarehouseReturns()
    },
    'activeTab' () {
      this.selected = []
      this.merchantFilter = ''
      this.returnsSelected = []
      this.inTransitSelected = []
      this.returnRequestedSelected = []
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab]
        }
      }).catch(() => {})
    },
    '$route.params.warehouseID' () {
      this.activeParentTab = 0
      this.searchVal = ''
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      common.manageLoadWarehouses(this.formatWarehouses, this)
    },
    activeParentTab (val) {
      this.searchVal = ''
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.$router.push({
        query: {
          tab: this.tabsDic[val === 0 ? 0 : this.activeTab > 3 ? this.activeTab : 3],
          page: this.$route.query.page ? this.$route.query.page : 1
        }
      }).catch(() => {})
    }
  },
  methods: {
    changeInTransitPageNumber (val) {
      this.paginationInTransitObj.currentPage = val === 'next' ? this.paginationInTransitObj.currentPage + 1 : this.paginationInTransitObj.currentPage - 1
      this.loadWarehouseReturns(this.paginationInTransitObj[val])
    },
    changePickupPageNumber (val) {
      this.paginationPickupObj.currentPage = val === 'next' ? this.paginationPickupObj.currentPage + 1 : this.paginationPickupObj.currentPage - 1
      this.loadReturns(this.paginationPickupObj[val])
    },
    confirmReturns (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure you want to confirm these Counter Returns?'],
        accept: this.updateReturnsStatus
      })
    },
    updateReturnsStatus () {
      const trackings = []
      this.returnsSelected.forEach(item => {
        trackings.push({
          order: item.id,
          packages: item.packages.map(item => item.package.id),
          status: 'returned'
        })
      })
      sendRequest(false, false, this, 'api/v1/tracking/', 'post', trackings, true,
        () => {
          this.merchantFilter = {}
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
        }
      )
    },
    printTaskList () {
      window.print()
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab]
        }
      }).catch(() => {})
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadWarehouseReturns()
    },
    searchReturnPickup (search) {
      this.searchReturnValPickup = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadReturnPickupSearch)
    },
    loadReturnPickupSearch () {
      if (this.searchReturnPickupInProgress || this.searchedReturnPickupValue === this.searchReturnValPickup) {
        return
      }
      this.loadReturns()
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.loadWarehouseReturns()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadWarehouseReturns (cursorVal) {
      if (!cursorVal) this.paginationInTransitObj.currentPage = 1
      this.searchInProgress = true
      this.returnsTableLoader = true
      const query = `&cursor=${cursorVal ? cursorVal : ''}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/returns/?current_warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&status=in_transit${query}`, 'get', null, true, 
        (response) => {
          this.returnsOrders = response.data.results
          this.paginationInTransitObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationInTransitObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.returnsTableLoader = false
          this.loadReturns()
        }
      )
      this.searchInProgress = false
    },
    loadReturns (cursorVal) {
      if (!cursorVal) this.paginationPickupObj.currentPage = 1
      this.returnsPickupsTableLoader = true
      this.searchReturnPickupInProgress = true
      sendRequest(true, false, this, `api/v1/returns/?status=return_requested&inbound_warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchReturnValPickup}`, 'get', null, true, 
        (response) => {
          this.returnsPickups = response.data.results
          this.returnsPickupsTableLoader = false
          this.paginationPickupObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationPickupObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedReturnPickupValue = this.searchReturnValPickup
        }
      )
      this.searchReturnPickupInProgress = false
    }
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    common.manageLoadWarehouses(this.formatWarehouses, this)
    this.activeParentTab = [3, 4].includes(this.activeTab) ? 1 : 0
  },
  components: {
    ManagerOrders,
    HeadComponent,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    PrintLabelButton,
    MerchantsButton,
    CounterReturns,
    CycleCount,
    ShipbluCursorPagination
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.hide-tabs {
  .con-ul-tabs {
    display: none;
  }
  .con-tab > div {
    margin-top: 0 !important;
  }
}
</style>