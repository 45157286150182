<template>
  <div id="demo-basic-card">
    <div class="mb-4">
      <h2>{{$t('Task List View')}}</h2>
      <div v-if="taskSheet.customer_agent" class="mt-4 md:flex justify-between items-center">
        <p class="text-3xl font-semibold"> {{$t('Cash In-hand')}}: {{ cashCollected }} {{$t('EGP')}} </p>
        <div class="text-right">
          <vs-button
            v-if="!taskSheet.task_sheet_cash_collected &&
            (taskSheet && taskSheet.task_sheet.length > 0) && checkOrdersIsDone()"
            color="success" @click="confirmTaskListModal = true">
            {{$t('Complete Task List')}}
          </vs-button>
          <vx-tooltip  v-else
            position="top" :text="`${$t('Task list is already completed or some orders are still with agent. Refresh the page.')}`">
            <vs-button :disabled="true" color="success" @click="confirmModal = true">{{$t('Complete Task List')}}</vs-button>
          </vx-tooltip>
        </div>
      </div>
    </div>
    <div class="grid lg:grid-cols-6 sm:grid-cols-4 grid-cols-2 gap-4 mb-6">
      <shipblu-card v-for="(slot, index) in slots" :key="index" :cardLoading="slotsLoading">
        <div @click="showPackages(warehouseUnitsDic[slot[0]])" class="text-center p-4" :class="warehouseUnitsDic[slot[0]].name === 'slots' ? 'cursor-pointer' : ''">
          <p class="text-primary font-semibold">{{slot[0]}}</p>
          <p class="text-blue-900 font-medium">{{slot[1]}}</p>
        </div>
      </shipblu-card>
    </div>

    <!-- Packages In Unit View -->
    <packages-in-unit :unitObj="unitObj" :unitViewActive="unitViewActive"
      @unitViewActive="unitViewActive = $event" @loadUnits="loadTaskSheetOrders" @slotsLoading="slotsLoading = $event"></packages-in-unit>

    <shipblu-prompt
      @close="closeModal"
      :active.sync="confirmModal"
      :title="$t('Confirm')"
      :buttons-hidden="true">
      <p class="text-blue-900 mb-2 leading-tight">{{ $t('Please, confirm you collected') + ' ' + cashCollected + ' ' + $t('EGP') + $t('. Type in the number below!') }}</p>
      <input class="input-order w-full mt-1" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="totalCashAmount">
      <div class="grid grid-cols-2 gap-4 mt-4">
        <vs-button @click="closeModal" type="border">{{ $t('Cancel') }}</vs-button>
        <vs-button :disabled="totalCashAmount !== cashCollected" @click="confirmCashCollected">{{ $t('Confirm') }}</vs-button>
      </div>
    </shipblu-prompt>
    <div v-if="taskSheet.merchant_agent" class="cursor-pointer vs-con-loading__container float-right" id="print-btn-loading">
      <div @click="printTaskList()">
        <vs-button color="success">{{$t('Print Returns')}}</vs-button>
      </div>
    </div>
    <shipblu-card :cardLoading="taskSheetOrdersLoading" class="mb-6 mt-16 p-5" v-for="shipment in taskSheetOrders" :key="shipment.index"
      :style="shipment.status === 'out_for_delivery' || shipment.status === 'out_for_return' || shipment.status === 'return_requested' || shipment.status === 'pickup_requested' || shipment.status === 'collection_requeted' || shipment.status === 'return_postponed' ?
        'background: #e8f35c69' : shipment.status === 'delivered' || shipment.status === 'returned' || shipment.status === 'picked_up' || shipment.status === 'collected' ?
        'background: rgb(220, 239, 210)' : shipment.status === 'return_attempted' || shipment.status === 'delivery_attempted' || shipment.status === 'collection_attempted' || shipment.status === 'on_hold' ?
        'background: rgb(254, 172, 174)' : shipment.status === 'in_transit' ? 'background: #e8e5e5' : ''">
      <div class="vx-row w-full">
        <div class="vx-col w-1/2">
          <p>{{$t('Tracking Number')}}: {{shipment.tracking_number}}</p>
          <p v-if="taskSheet.merchant_agent">{{$t('From')}}: {{shipment.merchant.name}}</p>
          <p v-else>{{$t('To')}}: {{shipment.customer.full_name}}</p>
        </div>
        <div class="vx-col w-1/2">
          <p>{{$t('Order Type')}}: {{shipment.is_exchange && shipment.tracking_number[0] === '3' ? 'Exchange' : shipment.tracking_number[0] === '1' && !shipment.rto_requested ? 'Delivery' : shipment.tracking_number[0] === '4' ?  'Cash Collection' : 'Return' }}</p>
          <p>{{$t('No. Packages')}}: {{shipment.packages? shipment.packages.length : ''}}</p>
          <p v-if="taskSheet.customer_agent">{{$t('Customer Address')}}: {{shipment.customer.address.line_1}} / {{shipment.customer.address.line_2}}</p>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div class="vx-row text-center mb-base" v-if="shipment.packages">
        <div class="vx-col w-1/3" v-for="item in shipment.packages" :key="item.index">
          <img class="w-12 ml-auto mr-auto mb-4" src="../../assets/images/pages/box.png"/>
          <div :class="item.scanned? 'text-green': ''">
            <p>{{item.package.package_size.short_code}} </p>
            <p>{{item.package_location.aisle_sign ? item.package_location.aisle_sign : item.package_location.code}}</p>
          </div>
        </div>
      </div>
      <div class="vx-row text-center mb-base" v-else>
        <div :class="shipment.cash_amount > 0 ? 'w-1/2' : 'w-full'">
          <div class="vx-col">
            <img :src="payImage " alt="content-img" class="ml-auto mr-auto w-16">
            <p class="text-lg font-semibold">{{$t('Cash Collection')}}</p>
          </div>
        </div>
      </div>
    </shipblu-card>

    <h2 v-if="taskSheet.task_sheet_supplies && taskSheet.task_sheet_supplies.length > 0" class="mb-6">{{$t('Supplies')}}</h2>
    <shipblu-card :cardLoading="taskSheetOrdersLoading" class="mb-6 p-5" v-for="supply in taskSheet.task_sheet_supplies" :key="supply.index">
      <div v-if="supply.supplies_request.status !== 'delivered'" class="justify-end vx-row w-full">
        <vs-button @click="editQuantity(supply)">{{$t('Actual Quantity')}}</vs-button>
      </div>
      <vs-divider></vs-divider>
      <div class="vx-row text-center">
        <div :class="item.package_size.short_code != 'OSZ' ? 'vx-col w-1/2 mb-2' : ''" v-for="item in supply.supplies_request.supplies_request_items" :key="item.index">
          <img v-if="item.package_size.short_code != 'OSZ'" class="w-12 ml-auto mr-auto mb-4" src="../../assets/images/pages/box.png"/>
          <div v-if="item.package_size.short_code != 'OSZ'" :class="supply.status === 'delivered'? 'text-green': ''">
            <p>{{item.package_size.short_code}} </p>
            <p>{{item.count_delivered}}</p>
          </div>
        </div>
      </div>
    </shipblu-card>
    <edit-supplies-count :supplyModal="supplyModal" :data="supply" @supplyModal="supplyModal = $event" @loadSupplies="loadTaskSheetOrders"></edit-supplies-count>
    <confirm-task-list :cashCollected="cashCollected" :confirmTaskListModal="confirmTaskListModal" @loadTaskSheetOrders="loadTaskSheetOrders" @confirmTaskListModal="confirmTaskListModal = $event"></confirm-task-list>
  </div>
</template>

<script>
import i18nData from '../../i18n/i18nData'
import boxImage from '../../assets/images/pages/box.png'
import payImage from '../../assets/images/pages/pay.png'
import {sendRequest} from '../../http/axios/requestHelper.js'
import common from '@/assets/utils/common'
import EditSuppliesCount from '../headOfFleet/components/EditSuppliesCount.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import PackagesInUnit from './components/PackagesInUnit.vue'
import axios from 'axios'
import ConfirmTaskList from '../headOfFleet/components/ConfirmTaskList.vue'

export default {
  data () {
    return {
      boxImage,
      payImage,
      allCash: 0,
      taskSheet: '',
      warehouse: {},
      taskSheetOrders: [],
      taskSheetOrdersLoading: false,
      ordersPackages: [],
      packagesInOrder: [],
      cashCollected: 0,
      warehousesDic: {},
      supplyModal: false,
      supply: {},
      merchantOrders: [],
      returns: [],
      slots: [],
      slotsLoading: false,
      order: {},
      totalCashAmount: null,
      confirmModal: false,
      warehouseUnitsDic: {},
      unitViewActive: false,
      unitObj: {},
      confirmTaskListModal: false
    }
  },
  methods: {
    checkOrdersIsDone () {
      let ordersCompleted = 0
      let cashCollectionDone = 0
      this.taskSheet.task_sheet.forEach(item => {
        if (item.order) {
          switch (item.order.status) {
          case 'return_requested':
            ordersCompleted += 1
            break
          case 'in_transit':
            ordersCompleted += 1
            break
          case 'delivered':
            ordersCompleted += 1
            break
          }
        } else if (item.cash_collection) {
          switch (item.cash_collection.status) {
          case 'collected':
            cashCollectionDone += 1
            break
          case 'collection_attempted':
            cashCollectionDone += 1
            break
          }
        }
      })
      return this.taskSheet.task_sheet.length === ordersCompleted + cashCollectionDone
    },
    showPackages (unit) {
      if (unit.name === 'slots') {
        this.unitViewActive = true
        this.unitObj = unit
      }
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
    },
    editQuantity (supply) {
      this.supplyModal = true
      this.supply = supply.supplies_request
    },
    loadTaskSheetOrders () {
      this.taskSheetOrdersLoading = true
      sendRequest(true, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/`, 'get', null, true,
        (response) => {
          this.taskSheetOrders = []
          this.allCash = 0
          this.cashCollected = 0
          this.taskSheet = response.data
          this.slotsLoading = true
          const slotRepeate = []
          response.data.task_sheet.forEach(element => {
            if (element.order) {
              element.order.packages.forEach(item => {
                this.warehouseUnitsDic[item.package_location.bin ? item.package_location.bin.aisle_sign : item.package_location.aisle_sign] = {
                  id: item.package_location.aisle_sign === 'In Counter' ? '' : item.package_location.bin ? item.package_location.bin.id : item.package_location.id,
                  name: item.package_location.aisle_sign === 'In Counter' ? '' : item.package_location.bin ? 'seals' : item.package_location.to_warehouse ? 'bins' : 'slots',
                  aisle_sign: item.package_location.aisle_sign
                }
                slotRepeate.push(item.package_location.aisle_sign ? item.package_location.aisle_sign : item.package_location.bin.aisle_sign)
              })
            }
          })
          const uniqueElements = [...new Set(slotRepeate)]
          this.slots = uniqueElements.map(element => [
            element,
            slotRepeate.filter(item => item === element).length
          ])
          this.slotsLoading = false
          // Add sequence and operation status in task sheet order
          response.data.task_sheet.map(element => {
            if (element.order) {
              element.order.operationsStatus = element.operations_status
              element.order.sequence = element.sequence
              element.order.taskSheetOrderID = element.id
              this.taskSheetOrders.push(element.order)
            } else {
              element.cash_collection.sequence = element.sequence
              element.cash_collection.operationsStatus = element.operations_status
              this.taskSheetOrders.push(element.cash_collection)
            }
          })
          // Sort Task Sheet Orders BY SEQUENCE
          this.taskSheetOrders.sort(function (order, secondOrder) { return order.sequence - secondOrder.sequence })
          //add Local State to PACKAGES
          if (this.taskSheet.status !== 'completed'  || new Date(this.taskSheet.created).toISOString() < new Date('02/09/2022').toISOString()) {
            this.taskSheetOrders.forEach(element => {
              if (element.packages) {
                element.packages.map(item => {
                  item.scanned = ''
                  item.tracking = element.tracking_number
                  item.rto = element.rto_requested
                  item.orderId = element.id
                  item.packageId = element.tracking_number[0] === '1' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '1') ? `1${item.package.id}` : `2${item.package.id}`
                  item.merchantForceScanPackage = element.merchant.force_scan_package_size_barcode
                })
              }
              if ((element.tracking_number[0] === '1' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '1')) && parseInt(element.cash_amount) > 0) {
                this.allCash = this.allCash + element.cash_amount
                if (element.status === 'delivered' && element.is_paid === false) {
                  this.cashCollected = this.cashCollected + element.cash_amount
                }
              } else if (element.tracking_number[0] === '4') {
                this.allCash = this.allCash + element.amount
                if (element.status === 'collected') {
                  this.cashCollected = this.cashCollected + element.amount
                }
              } else if ((element.tracking_number[0] === '2' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '2')) && parseInt(element.cash_amount) > 0) {
                if (element.status === 'picked_up' || element.status === 'in_transit') {
                  this.cashCollected = this.cashCollected - element.cash_amount
                }
              }
            })
            this.cashCollected += this.taskSheet.custody_status === 'received_by_agent' ? Number(this.taskSheet.custody_from_khazna) : 0
          } else {
            this.taskSheetOrders.forEach(element => {
              if ((element.tracking_number[0] === '1' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '1')) && parseInt(element.cash_amount) > 0) {
                this.allCash = this.allCash + element.cash_amount
                if (element.operationsStatus === 'completed' && element.is_paid === false) {
                  this.cashCollected = this.cashCollected + element.cash_amount
                }
              } else if (element.tracking_number[0] === '4') {
                this.allCash = this.allCash + element.amount
                if (element.operationsStatus === 'completed') {
                  this.cashCollected = this.cashCollected + element.amount
                }
              } else if ((element.tracking_number[0] === '2' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '2')) && parseInt(element.cash_amount) > 0) {
                if (element.operationsStatus === 'completed') {
                  this.cashCollected = this.cashCollected - element.cash_amount
                }
              }
            })
            this.cashCollected += this.taskSheet.custody_status === 'received_by_agent' ? Number(this.taskList.custody_from_khazna) : 0
          }
          this.ordersPackages = this.taskSheetOrders.map(item => item.packages).flat()
          this.returns = this.taskSheetOrders.filter(element => element.tracking_number[0] === '2' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '2') || element.rto_requested === true)
          this.returns.forEach(element => {
            element.merchantName = element.merchant.name
          })
          const groupBy = (xs, key) => {
            return xs.reduce((rv, x) => {
              (rv[x[key]] = rv[x[key]] || []).push(x)
              return rv
            }, {})
          }
          this.merchantOrders = groupBy(this.returns, 'merchantName')
          this.taskSheetOrdersLoading = false
        }
      )
    },
    confirmCash () {
      this.confirmModal = true
    },
    closeModal () {
      this.confirmModal = false
      this.totalCashAmount = null
    },
    confirmCashCollected () {
      this.$vs.loading()
      const totalCash = {
        total_orders_cash_amount: this.totalCashAmount
      }
      sendRequest(false, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/confirm-cash-collected/`, 'post', totalCash, true,
        (response) => {
          this.taskSheet.task_sheet_cash_collected = true
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.$vs.loading.close()
          this.closeModal()
        }, (error) => {
          common.notifyResponseError(this, error)
          this.$vs.loading.close()
        }
      )
    },
    loadManager () {
      sendRequest(false, false, this, `api/v1/users/managers/${this.$store.state.AppActiveUser.uid}/`, 'get', null, true,
        (response) => {
          this.warehouse = response.data.primary_warehouse
        }
      )
    },
    printTaskList () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#print-btn-loading',
        scale: 0.45
      })
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v2/task-sheets/${this.$route.params.taskListID}/print-returns/`,
        method: 'POST',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ShipBlu AWBs.pdf')
        document.body.appendChild(link)
        link.click()
        this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
      }).catch((error) => {
        if (error && error.response && error.response.status === 400 && error.response.status) {
          this.$vs.notify({
            color:'danger',
            title: i18nData[this.$i18n.locale]['Failed'],
            text: i18nData[this.$i18n.locale]['There is no return orders in this task sheet.'],
            position: 'top-center'
          })
        }
        this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
      })
    }
  },
  components: {
    EditSuppliesCount,
    ShipbluCard,
    ShipbluPrompt,
    PackagesInUnit,
    ConfirmTaskList
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')  
  },
  created () {
    this.loadManager()
    this.loadTaskSheetOrders()
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>
<style scoped>
.card-img-top {
  height: 350px;
}
</style>
<style lang='scss'>
#order-label-print{
  display: none;
  visibility: hidden;
}
.vs-collapse-item--icon-header {
  margin-top: 21px !important;
  margin-left: 31px !important;
}
.vs-collapse-item--icon-header > i {
  font-size: 30px;
}
.open-item .vs-collapse-item--content {
  max-height: fit-content !important;
}
.input-order {
  border: none;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  color: #1C5BFE;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: inherit;
  &::placeholder {
    color: #9DADC2 !important;
  }
}
</style>