<template>
  <div id="page-user-view">
    <div class="grid xl:grid-cols-5 gap-4">
      <div class="xl:col-span-3">
        <latest-statment :merchant="merchant" :financials="financials" :cardLoader="tableLoader" />
        <top-up :merchant="merchant"  class="mt-4" />
      </div>
      <div class="relative xl:col-span-2">
        <transaction-history />
      </div>
    </div>

    <!-- Summary Cash Amount -->
    <shipblu-card :cardLoading="summaryCashamount === ''" class="p-4 mt-4">
      <div class="sm:flex block leading-6 rounded-lg bg-color p-4">
        <div class="divide-x sm:w-1/2 w-full sm:pb-0 pb-3 sm:pr-4">
          <div class="flex items-center">
            <div class="w-10 h-10 rounded-full flex justify-center mr-3 border-primary">
              <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="text-primary"/>  
            </div>
            <p class="font-medium">{{$t('Next Statement Issuing Day')}}</p>
            <vx-tooltip boxShadow="0px 2px 10px rgba(28, 91, 254, 0.47)" textColor="#1C5BFE" color="#fff" :text="`${$t('The date we issue your next statement')}`">
              <feather-icon class="ml-2 cursor-pointer" icon="HelpCircleIcon" svgClasses="w-4 h-4 text-grey"></feather-icon>
            </vx-tooltip>
          </div>
          <div class="ml-12">
            <p class="text-2xl text-primary font-semibold">{{$t(daysDicReverse[nextStatment])}}</p>
            <p class="text-sm">( {{nextStatmentFormatted}} )</p>
          </div>
        </div>
        <div class="sm:w-1/2 w-full sm:pt-0 pt-3 sm:pl-4">
          <div class="flex items-center">
            <div class="w-10 h-10 rounded-full flex justify-center mr-3 border-primary">
              <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="text-primary"/>  
            </div>
            <p class="font-medium">{{$t('Next Transfer Day')}}</p>
            <vx-tooltip boxShadow="0px 2px 10px rgba(28, 91, 254, 0.47)" textColor="#1C5BFE" color="#fff" :text="`${$t('The date we transfer the collected cash')}`">
              <feather-icon class="ml-2 cursor-pointer" icon="HelpCircleIcon" svgClasses="w-4 h-4 text-grey"></feather-icon>
            </vx-tooltip>
          </div>
          <div class="ml-12">
            <p class="text-2xl text-primary font-semibold">{{$t(daysDicReverse[nextTransferDay])}}</p>
            <p class="text-sm">( {{nextTransferDayFormatted}} )</p>
            <p class="text-sm font-normal" v-for="(bankTransferDay, index) in transferCoversprev.filter(item => (item.day - 2 + 6) % 6  === nextTransferDay)" :key="index">
              {{$t('Transfer covers')}}:
              <span v-for="(Day, index2) in bankTransferDay.prevWeek" :key="'a'+index2" >
                <span v-if="Day.length !== 0">
                  <span>{{$t(daysDic[Day])}} </span>
                  <span v-if="( index2 !== bankTransferDay.prevWeek.length - 1 )">
                    {{$t(',')}}
                  </span>
                </span>
                <span v-if="Day.length !== 0 && index2 === bankTransferDay.prevWeek.length - 1"> ({{$t('from the previous week')}}) </span>
              </span>
              <span v-for="(DayNext, index1) in bankTransferDay.thisWeek" :key="'b'+index1" >
                <span v-if="( index1 === 0 && bankTransferDay.prevWeek.length > 0)">& </span>
                <span v-if="DayNext.length !== 0">
                  <span> {{$t(daysDic[DayNext])}} </span>
                  <span v-if="( index1 !== bankTransferDay.thisWeek.length - 1 )">{{$t(',')}}</span>
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </shipblu-card>

    <!-- Statements & Bank Transfers Full Schedule -->
    <shipblu-card :cardLoading="daysLoader" class="mt-4" :class="daysLoader ? 'p-4' : ''">
      <vs-collapse class="p-4">
        <vs-collapse-item class="bg-color rounded-lg">
          <div slot="header">
            <div class="b-color flex items-center">
              <feather-icon icon="CalendarIcon" svgClasses="h-9 w-6" class="align-middle b-color"/> 
              <span class="sm:text-base text-xs font-semibold ml-2">{{$t('Your Statements & Bank Transfers Full Schedule')}}</span>
            </div>
          </div>
          <div class="sm:flex block">
            <div class="divide-x pt-0 pb-3 pr-5">
              <p class="font-medium mb-1 text-blue-900 text-lg">{{$t('Statements Issuing Days')}}</p>
              <p class="flex gap-1 pl-3 text-primary text-lg">
                <span v-for="(billingDay, index) in billingDays" :key="index">
                  <span>{{$t(daysDic[billingDay])}}</span>
                  <span v-if="( index !== billingDays.length - 1 )">{{$t(',')}} </span>
                </span>
              </p>
            </div>
            <div class="sm:pl-5 sm:pt-0 pt-3">
              <p class="font-medium mb-1 text-blue-900 text-lg">{{$t('Bank Transfer Days')}}</p>
              <p class="pl-3" v-for="(bankTransferDay, index) in transferCoversprev" :key="index">
                <span class="font-medium text-blue-900 text-lg">{{daysDic[bankTransferDay.day]}}: </span>
                <span>{{$t('Transfer covers')}}: </span>
                <span v-for="(Day, index2) in bankTransferDay.prevWeek" :key="'a'+index2" >
                  <span class="text-primary" v-if="Day.length !== 0">
                    <span> {{$t(daysDic[Day])}}</span>
                    <span v-if="( index2 !== bankTransferDay.prevWeek.length - 1 )">{{$t(',')}}</span>
                  </span>
                  <span v-if="Day.length !== 0 && index2 === bankTransferDay.prevWeek.length - 1" class="capitalize"> ({{$t('from the previous week')}}) </span>
                </span>
                <span v-for="(DayNext, index1) in bankTransferDay.thisWeek" :key="'b'+index1" >
                  <span v-if="( index1 === 0 && bankTransferDay.prevWeek.length > 0)">& </span>
                  <span class="text-primary" v-if="DayNext.length !== 0">
                    <span>{{$t(daysDic[DayNext])}}</span>
                    <span v-if="( index1 !== bankTransferDay.thisWeek.length - 1 )">{{$t(',')}}</span>
                  </span>
                </span>
              </p>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </shipblu-card>

    <!-- Statements & Invoices Tabs -->
    <shipblu-card class="mt-4 p-4">
      <div class="wallet-tabs">
        <vs-tabs v-model="activeTab" class="tabs-shadow-none">
          <vs-tab icon-pack="feather" :label="$t('Statements')">
            <shipblu-table
              :sst="true"
              oredrs
              :data="statements"
              :tableLoader="tableLoader"
            >
              <div
                slot="header"
                class="flex flex-wrap-reverse items-center flex-grow justify-between"
              >
              </div>
              <template slot="thead">
                <shipblu-th>{{$t('Statement Date')}}</shipblu-th>
                <shipblu-th>{{$t('Statement Number')}}</shipblu-th>
                <shipblu-th>{{$t('Cash Collection Balance')}}</shipblu-th>
                <shipblu-th>{{$t('Balance Due')}}</shipblu-th>
                <shipblu-th>{{$t('Expected Payout')}}</shipblu-th>
                <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'merchant'">{{$t('Status')}}</shipblu-th>
                <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'merchant'">{{$t('Download Statement')}}</shipblu-th>
                <shipblu-th v-else>{{$t('Statement PDF')}}</shipblu-th>
                <shipblu-th>
                  <div class="flex justify-center">
                    {{$t('Transfer Details')}}
                    <vx-tooltip :text="`${$t('The Transfer details will be available once the amount is successfully transferred to your account')}`">
                      <feather-icon class="ml-2 cursor-pointer" icon="AlertCircleIcon" svgClasses="w-4 h-4 text-grey"></feather-icon>
                    </vx-tooltip>
                  </div>
                </shipblu-th>
              </template>

              <template slot-scope="{ data }">
                <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
                  <shipblu-td :data="data[indextr].id" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
                    <span class="text-darkblue">{{ new Date(data[indextr].created).toLocaleDateString('fr-CA')}}</span>
                  </shipblu-td>

                  <shipblu-td :data="data[indextr].number">
                    {{ data[indextr].number }}
                  </shipblu-td>

                  <shipblu-td :data="data[indextr].net_cod_due">
                    {{ data[indextr].v2 ? Number(data[indextr].v2.cash_closing_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </shipblu-td>

                  <shipblu-td :data="data[indextr].net_service_fees_due">
                    {{ data[indextr].v2 ? Number(data[indextr].v2.balance_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_service_fees_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </shipblu-td>

                  <shipblu-td :data="data[indextr].net_cod_due">
                    {{ data[indextr].v2 ? Number(data[indextr].v2.payout).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </shipblu-td>

                  <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'merchant'" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
                    <div class="m-auto flex items-center justify-center font-semibold">
                      <div><span class="status-icon mr-1"></span></div>
                      <span class="capitalize">{{ $t(getStatusLabel(data[indextr].v2 ? data[indextr].v2 : data[indextr])) }}</span>
                    </div>
                  </shipblu-td>

                  <shipblu-td :data="data[indextr].pdf_link" v-if="$store.state.AppActiveUser.userRole === 'merchant'">
                    <a @click="downloadStatement(data[indextr])" target="_blank" class="hover:underline">{{$t('PDF')}}</a> / 
                    <a @click="downloadExcelStatement(data[indextr])" target="_blank" class="hover:underline">{{$t('Excel')}}</a> 
                  </shipblu-td>

                  <shipblu-td :data="data[indextr].pdf_link" v-else>
                    <a @click="downloadStatement(data[indextr])" target="_blank">{{$t('Download')}}</a>
                  </shipblu-td>

                  <shipblu-td>
                    <div class="flex items-center justify-center">
                      <p class="flex" @click="viewBankDeposit(data[indextr])" :class="data[indextr].bank_deposits.length === 0 ? 'inactive-link text-darkgray' : 'text-primary hover:underline'">
                        <feather-icon icon="CreditCardIcon" svgClasses="w-4 h-4 mr-2"/>
                        <span>{{$t('view more')}}</span>
                      </p>
                    </div>
                  </shipblu-td>
                </shipblu-tr>
              </template>
            </shipblu-table>
            <shipblu-pagination :maximumItems="maximumItems" @changePageSize="changePageSize" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
          </vs-tab>
          <vs-tab icon-pack="feather" :label="$t('Invoices')">
            <invoices />
          </vs-tab>
        </vs-tabs>
      </div>
    </shipblu-card>

    <bank-deposits :bankDeposits="bankDeposits" :bankDepositModal="bankDepositModal" 
    @bankDepositModal="bankDepositModal = $event"></bank-deposits>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import BankDeposits from './BankDeposits.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import LatestStatment from './components/LatestStatment.vue'
import TopUp from './components/TopUp.vue'
import TransactionHistory from './components/TransactionHistory.vue'
import Invoices from './Invoices.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      statements: [],
      billingDays: [],
      merchant:[],
      nextStatment: '',
      nextStatmentFormatted: '',
      nextTransferDay: '',
      nextTransferDayFormatted: '',
      daysDicReverse: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday'
      },
      bankTransferDays: [],
      transferCoversprev:[],
      merchantID: '',
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      bankDepositModal: false,
      bankDeposits: [],
      file_URl: '',
      daysDic: {},
      pageSizes: [25, 50, 100],
      tableLoader: false,
      summaryCashamount: '',
      daysLoader: false,
      activeTab: 0,
      activeTabNameDic: {'statements': 0, 'invoices': 1},
      activeTabDic: {0: 'statements', 1: 'invoices'},
      latestStatment: '',
      financials: {
        cash_collection_balance: 0.0,
        service_fees_due: 0.0,
        refunds_in_progress: 0.0,
        cash_on_the_way: 0.0,
        amounts_on_hold: 0
      }
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          walletTab: this.activeTabDic[this.activeTab],
          page: this.currentPage,
          filter: this.$route.query.filter
        }
      }).catch(() => {})
      this.loadStatements()
    },
    'activeTab' (val) {
      if (val === 0) {
        this.loadStatements()
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          ...this.$route.query,
          walletTab: this.activeTabDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadStatements()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    getAccountStatusColor (order) {
      return common.getOrderStatusColor(order.accounting_status)
    },
    loadStatements () {
      this.tableLoader = true
      const query = `&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/statements/?merchant=${this.merchantID}&status=ready${query}`, 'get', null, true, 
        (response) => {
          this.statements = response.data.results
          if (this.offset === 0) {
            this.latestStatment = this.statements[0]
          }
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    viewBankDeposit (data) {
      this.bankDepositModal = true
      this.bankDeposits = data.bank_deposits
    },
    downloadStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/excel/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    loadDay () {
      this.daysLoader = true
      sendRequest(true, false, this, 'api/v1/subscriptions/pickup-days/', 'get', null, true,
        (response) => {
          response.data.map(item => { this.daysDic[item.id] = item.day })
          this.showNextDayStatement()
        }
      )
    },
    transferDays () {
      for (let i = 0; i < this.bankTransferDays.length; i++) {
        const statmentDay = i
        this.bankTransferDays[i] = this.bankTransferDays[i] + 1
        // make friday sunday
        if (this.bankTransferDays[i] === 7) this.bankTransferDays[i] = 2 
        let previousStatmentDay = i - 1
        if (previousStatmentDay < 0) previousStatmentDay = this.bankTransferDays.length - 1
        let transferWindow = (this.billingDays[statmentDay] - this.billingDays[previousStatmentDay] + 6) % 6
        if (transferWindow === 0) transferWindow = 6
        const prevWeek = []
        const thisWeek = []
        for (let j = 0; j < transferWindow; j++) {
          let cover = this.billingDays[statmentDay] - j - 1
          let isPrevWeek = false
          if (cover <= 0) {
            cover += 6
            isPrevWeek = true
          }
          if (this.bankTransferDays[statmentDay] > cover && !isPrevWeek) {
            thisWeek.push(cover)
          } else {
            prevWeek.push(cover)
          }
        }
        this.transferCoversprev.push({
          prevWeek: prevWeek.sort(),
          thisWeek: thisWeek.sort(),
          day: this.bankTransferDays[i]
        })
      }    
    },
    showNextDayStatement () {
      const date = new Date()
      const today = date.getDay()
      this.billingDays.sort()
      const realDaysArray = []
      this.billingDays.forEach((item) => {
        const realItem = item === 1 ? 6 : item - 2
        realDaysArray.push(realItem)
      })
      let indexPaymentDayIndex = 0
      let isLastDay = false
      for (let i = realDaysArray.length - 1; i >= 0; i--) {
        if (today < realDaysArray[i]) {
          indexPaymentDayIndex = realDaysArray[i]
          this.nextStatment = realDaysArray[i]
          isLastDay = true
        } else if (!isLastDay) {
          indexPaymentDayIndex = realDaysArray[0]
          this.nextStatment = realDaysArray[0] 
        }
      }
      let dayDelta = indexPaymentDayIndex - today
      dayDelta = (dayDelta + 7) % 7
      this.nextStatmentFormatted = new Date(date)
      this.nextStatmentFormatted.setDate(this.nextStatmentFormatted.getDate() + dayDelta)
      this.nextStatmentFormatted = this.changeFormate(this.nextStatmentFormatted)
      this.showNextDayStatementTransfer()
    },
    showNextDayStatementTransfer () {
      const date = new Date()
      const today = date.getDay()
      const paymentdays = []
      this.transferCoversprev.forEach((item, index) => {
        paymentdays[index] = item.day
      })
      paymentdays.sort()
      const realDaysArray = []
      paymentdays.forEach((item) => {
        const realItem = item === 1 ? 6 : item - 2
        realDaysArray.push(realItem)
      })
      let indexPaymentDayIndex = 0
      let isLastDay = false
      for (let i = realDaysArray.length - 1; i >= 0; i--) {
        if (today < realDaysArray[i]) {
          indexPaymentDayIndex = realDaysArray[i]
          this.nextTransferDay = realDaysArray[i]
          isLastDay = true
        } else if (!isLastDay) {
          indexPaymentDayIndex = realDaysArray[0]
          this.nextTransferDay = realDaysArray[0]
        }
      }
      let dayDelta = indexPaymentDayIndex - today
      dayDelta = (dayDelta + 7) % 7
      this.nextTransferDayFormatted = new Date(date)
      this.nextTransferDayFormatted.setDate(this.nextTransferDayFormatted.getDate() + dayDelta)
      this.nextTransferDayFormatted = this.changeFormate(this.nextTransferDayFormatted)
      this.daysLoader = false
    },
    changeFormate (oldFromat) {
      const today = oldFromat
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1
      let dd = today.getDate()
      if (dd < 10) dd = `0${dd}`
      if (mm < 10) mm = `0${mm}`
      return `${dd}/${mm}/${yyyy}`
    },
    summaryCash () {
      sendRequest(true, false, this, 'api/v1/billing/statements/summary/', 'get', null, true,
        (response) => {
          this.summaryCashamount = response.data
        }
      )
    },
    loadFinancials () {
      this.tableLoader = true
      sendRequest(true, false, this, 'api/v1/analytics/merchant/dashboard/financial-center/', 'get', null, true, 
        (response) => {
          this.financials = response.data
          this.tableLoader = false
        }
      )
    }
  },
  components: {
    BankDeposits,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard,
    LatestStatment,
    TopUp,
    TransactionHistory,
    Invoices,
    ShipbluPagination
  },
  created () {
    this.activeTab = this.$route.query.walletTab ? this.activeTabNameDic[this.$route.query.walletTab] : '0'
    this.$router.push({
      query: {
        tab: this.$route.query.tab,
        walletTab: this.activeTabDic[this.activeTab],
        page: this.$route.query.page,
        filter: this.$route.query.filter
      }
    }).catch(() => {})
    const merchantData = common.checkMerchantInLocalStorage(this)
    let userMerchant = {}
    merchantData.then(results => {
      this.merchant = results.merchantData
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.merchantID = this.merchant.id
      this.billingDays = this.merchant.billing_days.sort()
      this.bankTransferDays = [...this.billingDays]
      userMerchant = results.userMerchantData
      if (userMerchant.access_financials) {
        this.loadStatements()
        this.loadFinancials()
        this.loadDay()
        this.transferDays()
        this.summaryCash()
      }
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.bg-color {
  background-color: #7098ff17;
}
.bottom-card {
  background-color: #ECF3FB;
}
.divide-x {
  border-right: 2px solid rgba(28, 91, 254, 0.15);
  @media (max-width: 576px) {
    border-bottom: 2px solid rgba(28, 91, 254, 0.15);
    border-right: 0px;
  }
}
.text-darkgray {
  color: #6C84A3;
}
.wallet-tabs {
  .con-ul-tabs {
    width: 100%;
    .vs-tabs--ul {
      display: flex;
      border: 0px;
      border-bottom: 1px solid #9DADC2 !important;
    }
    .line-vs-tabs {
      display: none;
    }
    .vs-tabs-primary .con-ul-tabs .activeChild button, .vs-tabs-primary .con-ul-tabs button:not(:disabled):hover {
      color: rgba(var(--vs-primary),1)!important;
    }
    .vs-tabs--li {
      button.vs-tabs--btn {
        transition: none;
      }
      &.activeChild::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 2px;
        width: 100%;
        background: #1C5BFE;
      }
    }
  }
}
</style>