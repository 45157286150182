<template>
  <div @click="updateLocale(getCurrentLocaleData.lang === 'English' ? 'en' : 'ar')" class="cursor-pointer sm:mr-4 mr-2">
    <span class="cursor-pointer flex gap-2 items-center">
      <img :class="`${$route.params.lang === 'ar' ? 'mb-1' : ''} h-4 w-5`" :src="i18n_locale_img" :alt="$i18n.locale" />
      <span class="hidden sm:block">{{ getCurrentLocaleData.lang }}</span>
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    i18n_locale_img () {
      // Use below code to dynamically fetch image instead of if-else
      // NOTE: We used if-else because laravel throws error in 'yarn prod'.
      // If you are not using laravel + Vue, you can use below code to dynamically get image
      // return require(`@/assets/images/flags/${this.$i18n.locale}.png`)

      const locale = this.$i18n.locale
      if (locale === 'en')      return require('@/assets/images/flags/ar.png')
      else if (locale === 'ar') return require('@/assets/images/flags/en.png')
      else return require('@/assets/images/flags/en.png')
    },
    getCurrentLocaleData () {
      if (this.$route.params.lang === 'en') return { flag: 'ar', lang: 'العربية' } 
      else return { flag: 'us', lang: 'English' }
    }
  },
  methods: {
    updateLocale (locale) {
      this.$cookies.set('language', locale, '100y')
      localStorage.setItem('locale', locale)
      if (locale === 'ar') this.$vs.rtl = true
      else if (locale === 'en') this.$vs.rtl = false
      this.$router.push({
        params: {lang: this.$cookies.get('language')}
      }).catch(() => {})
      this.$i18n.locale = this.$cookies.get('language')
    }
  }
}
</script>