
<template>
  <vx-card style="direction: ltr !important;" id="order-label-print">
    <div>
      <div class="order-label" v-for="(label, index) in selected" :key="index">
        <div v-if="label.tracking_number[0] === '4'" class="label">
          <table class="table" border="2">
            <tr>
              <td width="10" class="border-none p-5 w-4/12">
                <div class="flex">
                  <img class="w-full" src="../../../assets/images/logo/ShipBlu Logo Large.png">
                </div>
              </td>
              <td class="border-none w-2/12">
                <h2 class="mt-8 uppercase text-center font-semibold"></h2>
              </td>
              <td class="pt-2 w-4/12">
                <div>
                <p class="uppercase font-semibold">cash collection</p>
                  <div class="flex">
                    <p class="uppercase w-1/2">created on: </p>
                    <p class="w-1/2"> {{ new Date(label.created).toLocaleDateString('fr-CA') }}</p>
                  </div>
                </div>
              </td>
              <td class="text-center w-4/12">
                <h2 class="mt-2">{{ label.amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}<br> (EGP)</h2>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="pb-1 text-center">
                <h2 class="font-bold uppercase">SERVICE LEVEL</h2>
              </td>
            </tr>
            <tr class="label-hdr">
              <td colspan="2" class="p-1 w-7/12">
                <div class="label-content">
                  <div class="flex customer-info">
                    <p class="uppercase w-1/3">From: </p>
                    <p class="w-2/3 address">
                      {{label.merchant.display_name ? label.merchant.display_name : label.merchant.name}}<br/>
                      <span>{{$t(label.merchant.address.zone.city.name)}}</span>
                    </p>
                  </div>
                  <div class="flex mb-3">
                    <p class="uppercase w-1/3">order no: </p>
                    <p class="w-2/3">{{label.merchant_order_reference}} </p>
                  </div>
                  <div class="flex customer-info">
                    <p class="uppercase  w-1/3">to: </p>
                    <p class="w-2/3 address">
                      {{label.customer.full_name}}<br/>
                      <span> {{label.customer.address.line_1}}</span>
                      <span v-if="label.customer.address.line_2 !== 'null'">, {{label.customer.address.line_2}}, </span><br/>
                      <span> {{$t(label.customer.address.zone.name)}}</span>
                      <span>, {{$t(label.customer.address.zone.city.name)}}</span>
                      <span>, {{$t(label.customer.address.zone.city.governorate.name)}}</span>
                    </p>
                  </div>
                  <div class="flex notes">
                    <p class="uppercase w-1/3">delivery notes: </p>
                    <p class="w-2/3">{{label.order_notes}}</p>
                  </div>
                </div>
              </td>
              <td colspan="2" class="p-1 w-6/12">
                <p class="uppercase font-semibold mb-6">PAYMENT RECIEPT</p>
                <p class="uppercase mb-6">AMOUNT TO BE COLLECTED:</p>
                <div class="vs-row flex py-1">
                  <p class="vs-col uppercase text-2xl pb-2 font-bold w-1/3">EGP</p>
                  <div class="vs-col w-2/3 border-2 border-solid p-1 mb-2">{{ label.amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                </div>
                <p class="mb-6">
                  THIS IS YOUR PAYMENT RECIEPT.<br/> PLEASE KEEP FOR YOUR RECORDS
                </p>
                <div>
                  <p class="w-full">{{label.merchant.display_name ? label.merchant.display_name : label.merchant.name }}</p>
                  <div class="mb-1">
                    <p class="w-full"></p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="label-footer text-center">
                <div class="pb-7 mt-5 text-lg">
                  <p>THIS IS YOUR PAYMENT RECEIPT.</p>
                  <p>PLEASE KEEP FOR YOUR RECORDS.</p>
                  <p>نرجو الإحتفاظ بهذا الإيصال</p>
                </div>
              </td>
              <td colspan="2" class="label-footer text-center">
                <p class="text-lg font-semibold uppercase mt-2">tracking number</p>
                <div class="text-xl mt-2">
                  <p class="ml-2 mr-2">{{label.tracking_number}}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <template v-else>
          <div v-for="(item, packageIndex) in label.packages" :key="packageIndex">
            <div class="label">
              <table class="table" border="2">
                <tr>
                  <td width="10" class="p-5 w-4/12">
                    <div class="flex">
                      <img class="w-full" src="../../../assets/images/logo/ShipBlu Logo Large.png">
                    </div>
                  </td>
                  <td class="flex text-center border-none">
                    <h2 class="mt-8 uppercase text-center font-semibold">{{label.tracking_number[0] === '1' || (label.tracking_number[0] === '3' && label.tracking_number[1] === '1') ? 'DY': 'RT'}}</h2>
                    <img class="w-16 self-center mt-5 ml-16" :src="label.is_customer_allowed_to_open_packages ? openPackage : doNotOpenPackage"> 
                  </td>
                  <td class="pt-2 w-5/12">
                    <div>
                      <p class="uppercase font-semibold">{{label.is_exchange ? 'EX': label.tracking_number[0] === '1' ? 'Delivery': 'Return'}}</p>
                      <div class="flex">
                        <p class="uppercase w-1/2">package no: </p>
                        <p class="w-1/2">{{++packageIndex}}</p>
                      </div>
                      <div class="flex">
                        <p class="uppercase w-1/2">packages: </p>
                        <p class="w-1/2">{{label.packages.length}}</p>
                      </div>
                      <div class="flex">
                        <p class="uppercase w-1/2">created on: </p>
                        <p class="w-1/2">{{ new Date(label.created).toLocaleDateString('fr-CA') }}</p>
                      </div>
                    </div>
                  </td>
                  <td class="w-1/12">
                    <img v-if="item.package.fragile" class="w-16 mt-6 mr-auto ml-auto" src="../../../assets/images/pages/fragile.png">
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-center py-1">
                    <h2 class="font-bold uppercase">{{label.service_level ? label.service_level.name : ''}}</h2>
                  </td>
                  <td class="text-center py-1">
                    <h2 class="font-bold uppercase">{{label.outbound_warehouse.code}}</h2>
                  </td>
                </tr>
                <template v-if="label.customer.address.zone.id !== 284">
                  <tr class="label-hdr">
                    <td colspan="2" class="p-1 w-7/12">
                      <div class="label-content">
                        <div class="flex customer-info">
                          <p class="uppercase w-1/3">From: </p>
                          <p class="w-2/3 address">
                            {{label.merchant.display_name ? label.dba ? label.dba + ' - ' + label.merchant.display_name : label.merchant.display_name :  label.dba ? label.dba + ' - ' + label.merchant.name : label.merchant.name}}<br/>
                            <span>{{ label.pickup_point ? $t(label.pickup_point.address.zone.city.governorate.name) : ''}}</span>
                          </p>
                        </div>
                        <div class="flex mb-3">
                          <p class="uppercase w-1/3">order no: </p>
                          <p class="w-2/3">{{label.merchant_order_reference}} </p>
                        </div>
                        <div class="flex customer-info">
                          <p class="uppercase  w-1/3">to: </p>
                          <p class="w-2/3 address">
                            {{label.customer.full_name}}<br/>
                            <span> {{label.customer.address.line_1}}</span>
                            <span v-if="label.customer.address.line_2 !== 'null'">, {{label.customer.address.line_2}}, </span><br/>
                            <span> {{$t(label.customer.address.zone.name)}}</span>
                            <span>, {{$t(label.customer.address.zone.city.name)}}</span>
                            <span>, {{$t(label.customer.address.zone.city.governorate.name)}}</span>
                          </p>
                        </div>
                        <div class="flex notes">
                          <p class="uppercase w-1/3">delivery notes: </p>
                          <p class="w-2/3">{{label.order_notes}}</p>
                        </div>
                        <div class="hr-top text-center">
                          <p class="text-lg font-semibold pt-4 uppercase">tracking number</p>
                          <div class="flex items-center justify-center">
                            <div>
                              <svg class="barcode tracking-barcode" jsbarcode-displayvalue="false" jsbarcode-format="code128" :jsbarcode-value="label.tracking_number" jsbarcode-height="43"></svg>
                            </div>
                            <div>
                              <div class="text-xl">
                                <p class="ml-2 mr-2">{{label.tracking_number}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="p-1 w-6/12">
                      <p class="uppercase font-semibold mb-6">CASH ON DELIVERY RECIEPT</p>
                      <p class="uppercase mb-6">AMOUNT TO BE {{label.tracking_number[0] === '1' || (label.tracking_number[0] === '3' && label.tracking_number[1] === '1') ? 'COLLECTED': 'REFUND'}}</p>
                      <div class="vs-row flex py-1">
                        <p class="vs-col uppercase text-2xl pb-2 font-bold w-1/3">EGP</p>
                        <div class="vs-col w-2/3 border-2 border-solid p-1 mb-2">{{parseInt(label.cash_amount) > 0 ? (label.cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0}}</div>
                      </div>
                      <p class="mb-6">
                        THIS IS YOUR PAYMENT RECIEPT.<br/> PLEASE KEEP FOR YOUR RECORDS
                      </p>
                      <div class="hr-top flex items-center relative">
                        <qrcode-vue level="L" class="w-full h-full ml-16 mt-5" size="135" :value="packageValue(label, item)"/>
                        <p class="package-id">{{'*' + Array(String(item.package.id).length - 1).join("*") + String(item.package.id).slice(-2)}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="rotate-label w-16 mx-2 py-6">
                        <svg :jsbarcode-value="label.tracking_number[0] === '2' || (label.tracking_number[0] === '3' && label.tracking_number[1] === '2') ? '2' + item.package.id : '1' + item.package.id" 
                          class="barcode" jsbarcode-height="40" jsbarcode-width="2" jsbarcode-displayvalue="false" jsbarcode-format="code128"></svg>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4" class="sentence align-middle text-center py-1">
                    <h1 class="font-semibold">THIS ORDER MUST HAVE A CUSTOMER ZONE TO GENERATE A SHIPPING LABEL</h1>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>
  </vx-card>
</template>

<script>
import doNotOpenPackage from '../../../assets/images/pages/Don\'t Open.svg'
import openPackage from '../../../assets/images/pages/open-package.png'
import QrcodeVue from 'qrcode.vue'

export default {
  props: ['selected', 'type'],
  data () {
    return {
      doNotOpenPackage,
      openPackage,
      value: '123151512'
    }
  },
  methods: {
    packageValue (orderObj, packageItem) {
      /*eslint-disable*/
      const obj = orderObj.tracking_number[0] === '2' || (orderObj.tracking_number[0] === '3' && orderObj.tracking_number[1] === '2') ? '2' + packageItem.package.id : '1' + packageItem.package.id
      /*eslint-enable*/
      return obj
    }
  },
  components: {
    QrcodeVue
  }
}
</script>

<style lang="scss">
@media print{
  @page {
    size: letter;
  }
  .sentence {
    padding: 20px;
    height: 336px;
  }
  #fc_frame {
    display: none;
  }
  .hr-top {
    width: 100%;
    border-top: 1px solid lightgray;
  }
  .package-id {
    transform: rotate(90deg);
    position: absolute;
    margin-left: 180px;
    font-weight: 600;
    font-size: 20px;
  }
  .shipment-page,
  .dropdown-menu{
    *{
      visibility: hidden;
      color: black;
    }
    #order-label-print,
    #order-label-print *{
      visibility: visible;
    }
    #order-label-print{
      display: block;
    }
    #content-area {
      margin: 0 !important;
    }
    #order-label-print {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
    .order-label{
      padding: 0px;
    }
    .label{
      min-width: 100%;
      text-align: left;
      margin-top: 0px;
      margin-bottom: 26px;
    }
    .label-hdr{
      font-size: 13px;
      min-height: 182px;
      line-height: 1.2;
    }
    .address {
      height: 85px !important;
      max-height: 85px !important;
    }
    .label-footer{
      font-size: 13px;
    }
    .notes {
      line-height: 1;
      min-height: 31px;
      max-height: 33px;
    }
    .borderd {
      border: 2px solid black;
    }
    .table {
      border-collapse: collapse;
      border: 1px solid ;
    }
    .table td {
      width: 20%;
      vertical-align: top;
    }
    .tracking-barcode {
      width: 98px !important;
    }
    .tracking-postion {
      position: relative !important;
      top: 85px !important;
    }
    [dir=ltr] .tracking-postion {
      position: relative !important;
      top: 0 !important;
    }
    .package-barcode {
      width: 120px !important;
    }
    .logo {
      background: white;
      padding: 1px 3px;
      font-size: 10px;
      color: #e11f26;
      border: 2px solid #e11f26;
      margin: 0px 5px 5px 0px;
    }
  }
}
</style>
