<template>
  <div>
    <p class="font-medium text-lg text-black mb-3">{{$t('Customer Information & Address')}}</p>
    <shipblu-card :cardLoading="customerLoading" class="md:p-6 p-5">
      <div :v-model="shipment" autocomplete="nofill">
        <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-3">
          <div class="col-span-1">
            <div class="flex">
              <feather-icon icon="PhoneIcon" class="text-icon" svgClasses="w-4 h-4" />
              <p class="font-medium text-sm text-grey-dark ml-2">{{$t('Phone Number')}}</p>
            </div>
            <div class="relative">
              <input v-show="selectionInProgress" id="phone-input" autocomplete="nofill" class="w-full shadow-md h-9 input-on mt-2" name="phone number"  v-model="searchPhoneVal"
              v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }"/>
              <span class="absolute text-primary right-0 px-2" style="top: 10px;">
                <feather-icon class="w-4 cursor-pointer" icon="XIcon" @click="convertTextToSelect()"></feather-icon>
              </span>
            </div>
            <v-select style="z-index: 1 !important;" v-if="!selectionInProgress" class="w-full mt-2 select-border" id="phone-number" v-model="shipment.phone" @search:blur="handleChangePhone(shipment.phone)"
            autocomplete="nofill" name="phone number" label="label" :placeholder="$t('Your customer phone number')" :options="phones" search @search="handlePhoneNumberSearch" @keyup.enter.native="handlePhoneNumberSearch"/>
            <span class="text-danger text-sm">{{ errors.has('phone number') ? errors.first('phone number') : '&nbsp;' }}</span>
          </div>
          <div class="col-span-1">
            <div class="flex mb-2">
              <feather-icon icon="UserIcon" class="text-icon" svgClasses="w-4 h-4" />
              <p class="font-medium text-sm text-grey-dark ml-2">{{$t('Full Name')}}</p>
            </div>
            <input autocomplete="nofill" class="w-full shadow-md input-order" v-validate="'required'" name="full name" :placeholder="$t('Your customer full name')" v-model="shipment.full_name"/>
            <span class="text-danger text-sm">{{ errors.has('full name') ? errors.first('full name') : '&nbsp;' }}</span>
          </div>
        </div>
        <div class="mt-2">
          <div v-if="!showAdditional" class="flex items-center justify-start mt-4">
            <feather-icon @click="showAdditional = true" icon="PlusIcon" svgClasses="w-4 h-4 text-primary" />
            <p @click="showAdditional = true" class="text-sm font-medium py-1 px-2 text-lightgray">{{$t('Additional Phone Number')}}</p>
          </div>
          <div v-if="showAdditional">
            <input autocomplete="nofill" name="additional phone number" v-validate="{ regex: /(^0[0-9]\d{8,15}$|^\u0660\u0661[\u0660-\u0669][\u0660-\u0669]{8,15}$)/ }" :class="shipment.secondary_phone ? 'input-customer-info-filled' : ''" class="w-full input-order mt-2" :placeholder="$t('Additional Phone Number')" v-model="shipment.secondary_phone"/>
            <span class="text-danger text-sm">{{ errors.has('additional phone number') ? errors.first('additional phone number')  : '&nbsp;' }}</span>
          </div>
        </div>
        <div class="mt-8">
          <div class="flex justify-between items-center mb-2">
            <div class="flex gap-2">
              <img :src="ContactBook" class="w-4 h-5"/>
              <p class="font-medium text-sm text-grey-dark">{{completeAddress ? $t('Full Address') : $t('Address Line 1')}}</p>
            </div>
            <div class="flex gap-2 items-center relative">
              <img :src="robot" class="w-8 h-8"/>
              <vs-switch class="mt-1" v-model="activateAutoZone"/>
              <div class="pl-1">
                <vx-tooltip class="tooltip-icon" position="top" color="dark" :text="`${$t('We will auto select the Governorate ,City & Zone based on what you filled in the “Full Address” field')}`">
                  <p class="material-icons-outlined text-sm text-primary">info</p>
                </vx-tooltip>
              </div>
            </div>
          </div>
          <input autocomplete="nofill" :class="completeAddress ? 'w-full' : 'sm:w-2/3 w-full'" class="input-order" v-validate="'required|min:10'" name="full address" :placeholder="$t('Customer Address 1')" v-model="shipment.address.line_1" @change="activateAutoZone ? fillAddress() : ''"/>
          <p class="text-danger text-sm">{{errors.has('full address') ? errors.first('full address') : '&nbsp;' }}</p>
        </div>
        <template v-if="!completeAddress">
          <div class="mt-8">
            <div class="flex mb-2">
              <img :src="ContactBook" class="w-4 h-5"/>
              <p class="font-medium text-sm text-grey-dark ml-2">{{$t('Address Line 2')}}</p>
            </div>
            <input autocomplete="nofill" class="sm:w-2/3 w-full input-order" v-validate="'required'" name="line 2" :placeholder="$t('Customer Address 2')" v-model="shipment.address.line_2"/>
            <p class="text-danger text-sm">{{ errors.has('line 2') ? errors.first('line 2') : '&nbsp;' }}</p>
          </div>
          <div class="w-full md:flex block gap-4 mt-4">
            <div class="md:w-1/3 w-full">
              <div class="flex mb-2">
                <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4 text-icon" />
                <p class="font-medium text-sm text-grey-dark ml-2">{{$t('Governorate')}}</p>
              </div>
              <v-select v-on:input="shipment.customer_governorate ? loadCities(`${shipment.customer_governorate.id}`) : ''" class="select-border" autocomplete="nofill" name="governorate" v-model="shipment.customer_governorate" v-validate="'required'" :placeholder="$t('Select') + '*'" label="name" :options="governorates"/>
              <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
            </div>
            <div class="md:w-1/3 w-full md:mt-0 mt-4">
              <div class="flex mb-2">
                <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4 text-icon" />
                <p class="font-medium text-sm text-grey-dark ml-2">{{$t('City')}}</p>
              </div>
              <v-select v-on:input="shipment.customer_city ? loadZones(`${shipment.customer_city.id}`) : ''" class="select-border" autocomplete="nofill" name="city" v-model="shipment.customer_city"
                v-validate="'required'" :placeholder="$t('Select') + '*'" label="name" :options="cities"/>
              <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
            </div>
            <div class="md:w-1/3 w-full md:mt-0 mt-4">
              <div class="flex mb-2">
                <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4 text-icon" />
                <p class="font-medium text-sm text-grey-dark ml-2">{{$t('Zone')}}</p>
              </div>
              <v-select class="select-border" autocomplete="nofill" v-model="shipment.address.customerZone" v-validate="'required'"
                name="zone" :placeholder="$t('Select') + '*'" label="name" :options="zones"/>
              <span class="text-danger text-sm" v-show="errors.has('zone') || (shipment.address.customerZone && shipment.address.customerZone.id === 284)">
                {{ errors.has('zone') ? errors.first('zone') : (shipment.address.customerZone && shipment.address.customerZone.id === 284) ? 'This field may not be unzoned' : '' }}
                </span>
            </div>
          </div>
        </template>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import ShipbluVSelect from 'shipblu-vue-select'
import vSelect from 'vue-select'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import common from '../../../assets/utils/common'
import { sendRequest }  from '../../../http/axios/requestHelper'
import ContactBook from '../../../assets/images/pages/ContactBook.svg'
import robot from '../../../assets/images/pages/robot.svg'

export default {
  props: ['shipment', 'governorates', 'checkValidation', 'resetData', 'shipmentModal'],
  data () {
    return {
      robot,
      ContactBook,
      cities: [],
      zones: [],
      customerLoading: false,
      phones:[],
      merchant: {},
      searchPhoneVal: '',
      searchInProgress: false,
      searchedPhoneValue: ' ',
      showAdditional: false,
      completeAddress: localStorage.getItem('autoZone') ? localStorage.getItem('autoZone') !== 'false' : false,
      selectionInProgress: true,
      activateAutoZone: localStorage.getItem('autoZone') ? localStorage.getItem('autoZone') !== 'false' : false,
      user: {}
    }
  },
  watch: {
    'resetData' (val) {
      if (val === true) {
        localStorage.removeItem('orederCreationForm')
        this.selectionInProgress = false
        this.shipment.phone = ''
        this.convertTextToSelect()
        this.zones = []
        this.cities = []
        this.$emit('resetData', false)
      }
    },
    'checkValidation' () {
      this.$validator.validateAll().then(result => {
        if (result && this.shipment.address.customerZone && this.shipment.address.customerZone.id !== 284) {
          this.shipment.phone = this.selectionInProgress ? this.searchPhoneVal : this.shipment.phone
          this.$emit('allowToCreateCustomer', true)
        }
      })
    },
    'shipment.phone' (selected) {
      if (typeof (selected) === 'object') {
        this.shipment.full_name = selected ? selected.customer.full_name : ''
        this.searchPhoneVal = selected ? selected.customer.phone : ''
        this.shipment.email = selected && selected.customer.email !== null ? selected.customer.email : ''
        this.shipment.customer_governorate = selected ? selected.customer.address.zone.city.governorate : ''
        this.shipment.customer_city = selected ? selected.customer.address.zone.city : ''
        this.shipment.address.customerZone = selected ? selected.customer.address.zone : ''
        this.shipment.handShakeType = 1
        this.shipment.secondary_phone = selected && selected.customer.secondary_phone !== null ? selected.customer.secondary_phone : null
        this.shipment.address.line_1 = selected && selected.customer.address.line_1 !== null ? selected.customer.address.line_1 : ''
        this.shipment.address.line_2 = selected && selected.customer.address.line_2 !== null ? selected.customer.address.line_2 : ''
        this.fillAddress()
        this.selectionInProgress = true
      }
    },
    'shipment.customer_governorate.id' (val) {
      if (this.shipment.customer_governorate && !this.searchInProgress) {
        this.$emit('loadServicesAvailability', val)
        this.loadCities(val)
      }
    },
    'searchPhoneVal' (val) {
      if (typeof (val) === 'object') {
        this.shipment.full_name = val.customer.full_name
        this.shipment.phone = val.customer.phone
        this.shipment.email = val.customer.email === null ? '' : val.customer.email
        this.shipment.customer_governorate = val.customer.address.zone.city.governorate
        this.shipment.customer_governorate.id = val.customer.address.zone.city.governorate.id
        this.shipment.customer_city = val.customer.address.zone.city
        this.shipment.customer_city.id = val.customer.address.zone.city.id
        this.shipment.address.customerZone = val.customer.address.zone
        this.shipment.handShakeType = 1
        this.shipment.secondary_phone = 
        this.shipment.secondary_phone === null ? '' : val.customer.secondary_phone
        this.shipment.address.line_1 = 
        this.shipment.address.line_1 === null ? '' : val.customer.address.line_1
        this.shipment.address.line_2 = 
        this.shipment.address.line_2 === null ? '' : val.customer.address.line_2
      }
    },
    'shipment.is_counter_dropoff' (val) {
      if (this.$route.name === 'merchant-add-delivery-order' && (val === 3 || val.id === 3)) {
        this.orderType = 'Fulfillment'
      } else if (this.$route.name === 'merchant-add-delivery-order') {
        this.orderType = 'Regular'
      }
    },
    'shipment.customer_city.id' (val) {
      if (this.shipment.customer_city && val) {
        this.loadZones(val)
      }
    },
    selectionInProgress (val) {
      this.shipment.phone = this.selectionInProgress ? this.searchPhoneVal : this.shipment.phone
      this.$emit('selectionInProgress', val)
    },
    activateAutoZone (val) {
      this.completeAddress = val
      localStorage.setItem('autoZone', val)
    }
  },
  components: {
    vSelect,
    ShipbluVSelect,
    ShipbluPrompt,
    ShipbluCard
  },
  methods: {
    handleChangePhone (val) {
      if (val && typeof (val) === 'object') {
        this.searchPhoneVal = val.customer.phone
        this.selectionInProgress = true
      }
    },
    convertTextToSelect () {
      this.shipment.full_name = ''
      this.shipment.phone = ''
      this.shipment.email = ''
      this.shipment.customer_governorate = ''
      this.shipment.customer_city = ''
      this.shipment.address.customerZone = ''
      this.shipment.handShakeType = ''
      this.shipment.secondary_phone = ''
      this.shipment.address.line_1 = ''
      this.shipment.address.line_2 = ''
      this.selectionInProgress = false
    },
    handlePhoneNumberSearch (search) {
      this.searchPhoneVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadPhoneNumberSearch, 1000, 5)
    },
    loadPhoneNumberSearch () {
      if (this.searchInProgress || this.searchedPhoneValue === this.searchPhoneVal) {
        return
      }
      if (this.searchPhoneVal) {
        this.loadPhoneNumber()
      }
    },
    loadPhoneNumber () {
      this.searchInProgress = true
      sendRequest(true, false, this, `api/v1/delivery-orders/?customer_phone=${this.searchPhoneVal}`, 'get', null, true,
        (response) => { 
          this.phones = response.data.results
          this.phones.forEach(item => {
            item.label = `${item.customer.phone} ${item.customer.full_name}`
          })
          this.searchedPhoneValue = this.searchPhoneVal
          if (response.data.count === 0) {
            this.shipment.phone = this.searchedPhoneValue
          }
          this.selectionInProgress = this.phones.length === 0
          if (this.phones.length === 0) {
            const input = document.getElementById('phone-input')
            input.removeAttribute('style')
            input.setAttribute('value', this.searchPhoneVal)
            input.focus()
          }
        },
      )
      this.searchInProgress = false
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
        this.cities = this.cities.filter(item => item.id !== 91)
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    },
    checkedItem (element, array) {
      array.forEach(item => {
        if (item.name === element.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    fillAddress () {
      if (this.shipment.address.line_1.length > 10) {
        this.$validator.pause()
        this.completeAddress = false
        this.customerLoading = true
        const orderAddress = {
          full_address: this.shipment.address.line_1 ? this.shipment.address.line_1 : ''
        }
        sendRequest(true, false, this, 'api/v2/gpt/autozoner/', 'post', orderAddress, true, 
          (response) => {
            this.shipment.address = {
              ...response.data,
              customerZone: response.data.zone
            }
            this.shipment.customer_governorate = response.data.zone.city.governorate
            this.shipment.customer_city = response.data.zone.city
            this.customerLoading = false
            this.$validator.resume()
          }, () => {
            this.customerLoading = false
          }
        )
      }
    }
  },
  created () {
    if (this.shipment.customer_governorate) {
      this.$emit('loadServicesAvailability', this.shipment.customer_governorate.id)
      this.loadCities(this.shipment.customer_governorate.id)
    }
    if (this.shipment.customer_city) {
      this.loadZones(this.shipment.customer_city.id)
    }
    this.customerLoading = true
    setTimeout(() => {
      if (this.shipmentModal === true && localStorage.getItem('orderCreationForm') === null) {
        this.selectionInProgress = false
      } else {
        const orderObj = JSON.parse(localStorage.getItem('orderCreationForm')) 
        this.selectionInProgress = orderObj.phone !== ''
        this.searchPhoneVal = this.shipment.phone
      }
      this.customerLoading = false
    }, 1000)
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.user = results.merchantData
    })
  }
}
</script>

<style lang="scss">
.tooltip-icon {
  position: absolute;
  top: -7px;
  right: -2px;
}
.v-select {
  &.select-border {
    ul {
      min-width: fit-content;
    }
    ::placeholder {
      color: #9DADC2 !important;
    }
    .vs__selected {
      color: #1C5BFE;
    }
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    position: relative;
    .vs__dropdown-toggle {
      border: none;
    }
    .vs__actions {
      padding: 4px 2px 0px 3px;
      .vs__clear {
        margin-right: 0px;
      }
    }
  }
  .feather-chevron-down, .feather-x {
    stroke: #6C84A3;
  }
  ul {
    border: none;
    overflow-x: hidden;
    width: 100%;
  }
}
</style>