<template>
  <div class="shipments-page">
    <shipblu-table
      :sst="true"
      v-model="selected"
      multiple 
      orders
      @keyup.enter.native="handleSearch"
      @search="handleSearch"
      search 
      :data="notScannedOrders"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('PD')}}</shipblu-th>
        <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
        <shipblu-th>{{$t('Slots')}}</shipblu-th>
        <shipblu-th>{{$t('Estimated Delivery Date')}}</shipblu-th>
        <shipblu-th>{{$t('On Hold')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
         :to="{ name: 'head-of-fleet-order-queue-view', params: { warehouseID: $route.params.warehouseID, type: data[indextr].tracking_number[0] === '2' || (data[indextr].tracking_number[0] === '3' && data[indextr].tracking_number[1] === '2') ? 'returns' : 'delivery-orders', orderID: data[indextr].id }}">
          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchantName">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerName">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].zone">
            <p v-if="data[indextr].customer.address.zone.city">{{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].loaction">
            {{ data[indextr].customer.address.line_1 }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].preferredDate">
            {{ data[indextr].preferred_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].pickupDate">
            {{ data[indextr].pickup_date ? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].slots">
            <p v-for="packageItem in data[indextr].packages" :key="packageItem.index">{{ packageItem.package_location.aisle_sign ? packageItem.package_location.aisle_sign : packageItem.package_location.code }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].estimatedDate">
            {{ data[indextr].estimated_date}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].on_hold">
            <div v-if="data[indextr].on_hold !== null" class="flex gap-2 items-center">
              <div class="bg-warning h-3 w-3 inline-block rounded-full flex-shrink-0"></div>
              <span>On Hold</span>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages">
            {{ data[indextr].packages ? data[indextr].packages.length : 'N/A'}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages">
            <p v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{packageItem.package.package_size.short_code}}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import common from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['loadMissingData'],
  data () {
    return {
      localStorage,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      warehousesDic: {},
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page && Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      selected: [],
      notScannedOrders: [],
      searchOrdersVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.selected = []
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadOrders()
    },
    '$route.params.warehouseID' () {
      this.searchOrdersVal = ''
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.currentPage = 1
      common.manageLoadWarehouses(this.formatWarehouses, this)
    },
    selected () {
      this.$emit('selected', this.selected)
    },
    loadMissingData (val) {
      if (val) {
        this.loadOrders()
      }
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadOrders()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchOrdersVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchOrdersVal) {
        return
      }
      this.loadOrders()
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.loadOrders()
    },
    loadOrders () {
      this.searchInProgress = true
      this.tableLoader = true
      const tracking_numbers = localStorage.getItem('scannedOrders') ? JSON.parse(localStorage.getItem('scannedOrders')) : []
      const query = `&offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchOrdersVal}`
      sendRequest(true, false, this, `api/v1/warehousing/${this.$route.params.warehouseID}/cyclye-count/?${query}`, 'post', {tracking_numbers}, true, 
        (response) => {
          this.selected = []
          this.notScannedOrders = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
          this.searchedValue = this.searchOrdersVal
          this.$emit('loadMissingData', false)
        }
      )
      this.searchInProgress = false
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>