<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <h2>{{$t('Merchant Return Points')}}</h2>
          </div>
          <div v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales'" class="vx-col w-full lg:w-1/2 items-end">
            <div class="flex justify-between">
              <span class="font-semibold"></span>
              <span class="font-medium text-primary cursor-pointer">
                <vs-button
                  @click="newPickupPoint('Add Return Point')"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-plus"
                  size="large"
                  v-if="!(merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant' && ($store.state.AppActiveUser.userRole === 'sales' || $store.state.AppActiveUser.userRole === 'customer-support'))"
                >
                </vs-button>
              </span>
            </div>
          </div>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          :data="pickupPoints"
          :tableLoader="tableLoader"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
          </div>
          <template slot="thead">
            <shipblu-th>{{$t('Governorate')}}</shipblu-th>
            <shipblu-th>{{$t('City')}}</shipblu-th>
            <shipblu-th>{{$t('Zone')}}</shipblu-th>
            <shipblu-th>{{$t('Address')}}</shipblu-th>
            <shipblu-th>{{$t('Default')}}</shipblu-th>
            <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales'">{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].governorate">
                {{ data[indextr].address.zone ? $t(data[indextr].address.zone.city.governorate.name) : 'N/A' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].city">
                {{ data[indextr].address.zone ? $t(data[indextr].address.zone.city.name) : 'N/A' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].zone">
                {{ data[indextr].address.zone ? $t(data[indextr].address.zone.name) : 'N/A' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].address">
                {{ data[indextr].address.line_1 }} {{ data[indextr].address.line_2 }}
              </shipblu-td>
              <shipblu-td :data="data[indextr].is_default">
                <vs-radio v-if="editable" name="reason" v-validate="'required'" v-model="pickupPoint" :vs-value="data[indextr]"></vs-radio>
                <span v-else-if="!editable && data[indextr].is_default">
                  <feather-icon
                    icon="CheckCircleIcon"
                    svgClasses="h-6 w-7"
                    class="text-green"
                  />
                </span>
              </shipblu-td>
              <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales'">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-more-horizontal"
                    class="mr-2"
                  >
                  </vs-button>
                  <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="updatePickupPoint(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="CheckCircleIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Set Default')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="deletePickupPoint(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="TrashIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Delete')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
      </form>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-pickup-point :governorates="governorates" :cities="cities" :zones="zones"
    :type="type" :pickupPointModal="pickupPointModal" @pickupPointModal="pickupPointModal = $event" :address="address" @addPickupPoint="addPickupPoint"></add-pickup-point>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import AddPickupPoint from './AddPickupPoint.vue'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import i18nData from '../../../i18n/i18nData.js'
import common from '../../../assets/utils/common.js'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['merchant'],
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      pickupPoints: [],
      selected: [],
      pickupPoint: '',
      deleteData: {},
      pickupPointModal: false,
      type: 'Add Return Point',
      address: {
        city: {
          id: '',
          name: ''
        },
        governorate: {
          id: '',
          name: ''
        },
        address: {
          line_1: '',
          zone: ''
        }
      },
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      governorates: [],
      cities: [],
      zones: [],
      tableLoader: false,
      merchantID: ''
    }
  },
  watch : {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadPickupsPoints()
    },
    'address.governorate' (val) {
      if (this.address.governorate) {
        this.loadCities(val.id)
      }
    },
    'address.city' (val) {
      if (this.address.city) {
        this.loadZones(val.id)
      }
    }
  },
  methods: {
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    },
    loadPickupsPoints () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/merchants/${this.merchantID}/return-points/${query}`, 'get', null, true,
        (response) => {
          this.pickupPoints = response.data.results
          this.totalRows = response.data.count
          this.pickupPoints.forEach(element => {
            if (element.is_default) {
              this.pickupPoint = element
            }
          })
          this.tableLoader = false
        }
      )
    },
    newPickupPoint (type) {
      this.type = type
      this.address = {
        address: {
          line_1: '',
          zone: ''
        }
      }
      this.pickupPointModal = true
    },
    updatePickupPoint (data) {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/return-points/${data.id}/`, 'patch', {is_default: true}, true,
        () => {
          this.editable = false
          this.loadPickupsPoints()
        }
      )
    },
    addPickupPoint () {
      const address = { 
        address: {
          line_1: this.address.address.line_1,
          line_2: this.address.address.line_2,
          zone: this.address.address.zone.id
        }
      }
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/return-points/`, 'post', address, true,
        () => {
          this.pickupPointModal = false
          this.loadPickupsPoints()
        }
      )
    },
    deletePickupPoint (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/return-points/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadPickupsPoints()
        }
      )
    }
  },
  components: { 
    AddPickupPoint,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    if (this.$store.state.AppActiveUser.userRole !== 'commercial-admin') {
      this.loadGovernorates()
    }
    this.merchantID = this.$route.params.merchantID
    this.loadPickupsPoints()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
