<template>
  <div class="shipments-page">
    <table-filter v-if="$route.name !== 'head-of-fleet-in-house-orders'" :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
    <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
      <export-button class="mr-4 float-left whitespace-nowrap"></export-button>
      <print-label-button v-if="$route.name !== 'head-of-fleet-in-house-orders'" :selected="selected" class="mr-4 float-left whitespace-nowrap"></print-label-button>
    </div>
    <shipblu-table 
      v-model="selected" 
      orders 
      multiple 
      @searchDate="handleSearchDate"
      @search="searchReturn" 
      @keyup.enter.native="searchReturn"
      :max-items="maximumItems"
      :sst="true"
      searchDate
      search 
      :data="returns"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('Return request date')}}</shipblu-th>
        <shipblu-th>{{$t('Return attempts')}}</shipblu-th>
        <shipblu-th v-if="$route.name === 'head-of-fleet-in-house-orders'">{{$t('Slots')}}</shipblu-th>
        <shipblu-th>{{$t('Return date')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Call')}}</shipblu-th>
      </template>
      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: 'head-of-fleet-order-queue-view', params: { type: 'returns', orderID: data[indextr].id }}">
          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchantName">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerName">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].loaction">
            {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].loaction">
            {{ data[indextr].customer.address.line_1 }} / {{ data[indextr].customer.address.line_2 }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].estimatedDate">
            {{ data[indextr].estimated_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].returnAttempted">
            {{ data[indextr].return_attempts }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].slots" v-if="$route.name === 'head-of-fleet-in-house-orders'">
            <span v-for="packageItem in data[indextr].packages" :key="packageItem.index">{{ packageItem.package_location.aisle_sign ? packageItem.package_location.aisle_sign : packageItem.package_location.code }}</span>
          </shipblu-td>

          <shipblu-td :data="data[indextr].returnDate">
            {{ data[indextr].returned_date ? new Date(data[indextr].returned_date).toLocaleDateString('fr-CA') : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages">
            {{ data[indextr].packages.length }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages">
            <p v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{packageItem.package.package_size.short_code}}
            </p>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <div class="m-auto flex items-center py-4 justify-center font-semibold" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td>
            <a @click.stop :href="`tel:${data[indextr].customer.phone}`">
              <vs-button
                size="small"
                icon-pack="feather"
                icon="icon-phone"
                class="mr-2"
              ></vs-button>
              </a>
          </shipblu-td>
          
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from './components/ExportButton.vue'
import PrintLabelButton from './components/PrintLabelButton.vue'
import TableFilter from '../merchant/components/TableFilter.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'

export default {
  data () {
    return {
      localStorage,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      returns: [],
      warehousesDic: {},
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      filters: [],
      selected: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'return requested',
          value: 'return_requested'
        },
        {
          name: 'out for pickup',
          value: 'out_for_pickup'
        },
        {
          name: 'return attempted',
          value: 'return_attempted'
        },
        {
          name: 'picked up',
          value: 'picked_up'
        },
        {
          name: 'in transit & en route',
          value: 'in_transit, en_route'
        },
        {
          name: 'out for return',
          value: 'out_for_return'
        },
        {
          name: 'On Hold',
          value: 'on_hold'
        },
        {
          name: 'Returned',
          value: 'returned'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      searchReturnVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  watch: {
    '$route.params.warehouseID' () {
      this.searchReturnVal = ''
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      common.manageLoadWarehouses(this.formatWarehouses, this)
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadReturns()
      }
    },
    selected () {
      this.$emit('selected', this.selected)
    }
  },
  methods: {
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadReturns(this.paginationObj[val])
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadReturns()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all' || (this.filterValue === 'on_hold' && this.filters.includes('on_hold'))) {
        this.filters = []
        if (this.filterValue === 'on_hold' && !this.filters.includes('on_hold')) {
          this.filters.push('on_hold')
        }
      } else if (this.filters.includes('on_hold')) {
        this.filters.splice(this.filters.indexOf('on_hold'), 1)
      }
      this.$router.push({
        query: {
          filter: this.filters
        }
      }).catch(() => {})
      this.loadReturns()
    },
    handleSearchDate (start, end, criteria) {
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.criteria = criteria
      this.loadReturns()
    },
    searchReturn (search) {
      this.searchReturnVal = search && search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchReturnVal) {
        return
      }
      this.loadReturns()
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.loadReturns()
    },
    loadReturns (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.searchInProgress = true
      this.tableLoader = true
      const query = `cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchReturnVal}&${this.filters.includes('on_hold') ? 'on_hold=true' : this.$route.name === 'head-of-fleet-in-house-orders' ? 'status=in_transit' : `status=${this.filters}`}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(true, false, this, `api/v1/returns/?${this.$route.name === 'head-of-fleet-in-house-orders' ? 'current_warehouse' : 'inbound_warehouse'}=${this.warehousesDic[this.$route.params.warehouseID]}&${query}`, 'get', null, true, 
        (response) => {
          this.selected = []
          this.returns = response.data.results
          this.tableLoader = false
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchReturnVal
        }
      )
      this.searchInProgress = false
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    PrintLabelButton,
    TableFilter,
    ShipbluCursorPagination
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
#order-label-print{
  display: none;
  visibility: hidden;
}
</style>