<template>
  <div id="counter-label-print">
    <div class="noprint">
      <task-list-info :taskSheetOrders="taskSheetOrders" :returnOrderAmount="returnOrderAmount" :taskListInfoData="taskListInfoData" :cashCollected="cashCollected" :cridetCardCollected="cridetCardCollected" :dropOffsCash="dropOffsCash" @loadShipments="loadTaskListInfo"/>
      <div v-if="$store.state.AppActiveUser.userRole !== 'finance'" class="flex items-center gap-4 justify-end mt-3">
        <vs-button type="filled" @click="addOrders()" :disabled="agentsLoaded === false" v-if="taskListInfoData.status !== 'completed' && taskListInfoData.status !== 'failed'">{{$t('Add Orders')}}</vs-button> 
        <vs-dropdown v-if="common.formatDate(taskListInfoData.created) === common.formatDate(new Date().toJSON())" vs-trigger-click class="dd-actions cursor-pointer" :disabled="selected.length === 0" >
          <vs-button :class="selected.length === 0 ? 'disabled-style' : ''">
            {{$t('Move to Another Task List')}}
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-2 align-middle"/>
          </vs-button>
          <vs-dropdown-menu class="noprint">
            <vs-dropdown-item @click="openMoveOrdersModel(selected)">
              <span>{{$t('Move to existing task list')}}</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="openTaskListViewModal(selected)">
              <span>{{$t('Create and move to new task list')}}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div v-if="taskListInfoData.merchant_agent" class="cursor-pointer vs-con-loading__container relative" id="print-btn-loading">
          <div class="flex pl-4 py-2" @click="printTaskList()">
            <vs-button color="success">{{$t('Print Returns')}}</vs-button>
          </div>
        </div>
      </div>
      <div v-if="$route.query.tab === 'Orders'" class="main flex flex-wrap my-4 d-theme-dark-bg justify-between items-center">
        <div class="flex">
          <div class="relative m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop" v-for="status in orderTypes" :key="status.index" @click="changeFilter(status.value)"
            v-bind:style="(!$route.query.orderType || $route.query.orderType.length === 0) && status.value === 'all' ? `background:${status.color ? status.color : '#1C5BFE'}; color: white` : $route.query.orderType && $route.query.orderType.includes(status.value) ? `${getstyle(status.value)}; color: #fff;` : 'color: #6C84A3;'">
            <div class="text-sm capitalize">{{ $t(status.name) }}</div>
            <div v-if="status.value !== 'all'" class="absolute right-0 top-0 w-2 h-full rounded-4xl" :style="getstyle(status.value)"></div>
          </div>
        </div>
        <div class="flex" v-if="orderTypeFilter !== 'all' && orderTypeFilter !== ''">
          <div class="relative m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
            v-for="status in ordersStatus.filter(item => item.type === orderTypeFilter)" :key="status.index" @click="changeOrderStatusFilter(status.value)"
            v-bind:style="(!$route.query.filter || $route.query.filter.length === 0) && status.value === 'all' ? `background:${status.color ? status.color : '#1C5BFE'}; color: white` : $route.query.filter && $route.query.filter.includes(status.value) ? `${getstyle(status.value)}; color: #fff;` : 'color: #6C84A3;'">
            <div class="text-sm capitalize">{{ $t(status.name) }}</div>
            <div v-if="status.value !== 'all'" class="absolute right-0 top-0 w-2 h-full rounded-4xl" :style="getstyle(status.value)"></div>
          </div>
        </div>
        <div class="mr-20">
          <merchants-button class="py-2 px-4 cursor-pointer rounded-md" :merchantsPickupPoints="merchantsPickupPoints" @merchantFilter="merchantFilter = $event"></merchants-button>
        </div>
      </div>
      <vs-tabs v-model="activeTab">
        <vs-tab v-if="MATaskSheet" :label="$t('Overview')">
          <task-list-overview :overview="overview" :suppliesDic="suppliesDic" :overviewTableLoader="overviewTableLoader" :totalRow="totalRow"/>
        </vs-tab>
        <vs-tab :label="$t('Orders')">
          <task-list-orders :currentPage="currentPage" :taskListInfoData="taskListInfoData" :tableLoader="tableLoader" :taskList="taskList" :taskSheetOrders="taskSheetOrders" :selected="selected"
            @activeTaskFlag="activeTaskFlag = $event" @currentPage="currentPage = $event" @taskSheetOrder="taskSheetOrder = $event" :totalRows="totalRows" @loadFirstMileOrders="loadFirstMileOrders"
            @sequence="sequence = $event" @loadShipments="loadTaskListInfo" @maximumItems="maximumItems = $event" @selected="selected = $event" @offset="offset = $event"/>
        </vs-tab>
        <vs-tab v-if="MATaskSheet" :label="$t('Supplies')">
          <task-list-supplies :suppliesCurrentPage="suppliesCurrentPage" :suppliesTotalRows="suppliesTotalRows" :allSupplies="allSupplies" @supplyModal="supplyModal = $event" @supply="supply = $event"
            @suppliesOffset="suppliesOffset = $event" @suppliesCurrentPage="suppliesCurrentPage = $event"/>
        </vs-tab>
      </vs-tabs>
      <template v-if="taskListInfoData.status !== 'completed' && taskListInfoData.status !== 'failed' && $store.state.AppActiveUser.userRole !== 'finance'">
        <vs-button type="filled" color="danger" :disabled="!endDayFlag" @click="confirmUpdateTaskListStatus('failed')" class="float-right m-5 block">{{$t('Abort Task List')}}</vs-button>
        <vs-button type="filled" color="success" :disabled="!completeFlag" @click="taskListInfoData.customer_agent ? confirmTaskListModal = true : confirmUpdateTaskListStatus('completed')" class="float-right m-5 block">{{$t('Complete Task List')}}</vs-button>
      </template>
      <active-task-sheets :taskListInfoData="taskListInfoData" :taskSheetType="taskSheetType" :sequence="sequence" :taskSheetOrder="taskSheetOrder" :activeTaskFlag="activeTaskFlag"
        @activeTaskFlag="activeTaskFlag = $event" @loadShipments="loadTaskListInfo"></active-task-sheets>
      <edit-supplies-count @confirmUpdateTaskListStatus="confirmUpdateTaskListStatus" :supplyModal="supplyModal" :data="supply" @supplyModal="supplyModal = $event" @loadSupplies="loadShipments"></edit-supplies-count>

      <shipblu-prompt
        class="shipment-modal"
        @close="closeModal"
        :active.sync="closeTask"
        :title="$t('Complete Task List')"
        :buttons-hidden="true">
          <div>
            {{$t('You have')}} {{createdNum.length}} {{$t('supplies order(s) without actual quantity, are you sure you want to proceed?')}}
          </div>
          <div class="grid grid-cols-2 gap-4 mt-4">
            <button @click="redirectToSupplies()" class="btn disable-btn">{{ $t('No') }}</button>
            <button class="active-btn btn" @click="changeSupplieStatus()">{{ $t('Yes') }}</button>
          </div>
      </shipblu-prompt>
      <move-task-list-view :taskListInfoData="taskListInfoData" :moveTaskListModal="moveTaskListModal" :taskSheetType="taskSheetType" :agents="agents" :MATaskSheet="MATaskSheet" @moveTaskListModal="moveTaskListModal = $event" :sequence="sequence" :taskSheetOrder="taskSheetOrder" :activeTaskFlag="activeTaskFlag"
        @activeTaskFlag="activeTaskFlag = $event" @loadShipments="loadTaskListInfo"></move-task-list-view>
    </div>
    <div class="flex gap-8">
      <label class="font-semibold">{{$t('Stops')}}: <span class="text-black text-lg">{{taskListInfoData.stops}}</span></label>
      <label class="font-semibold">{{$t('Number Of Packages')}}: <span class="text-black text-lg">{{taskListInfoData.packages_count}}</span></label>
    </div>
    <confirm-task-list @loadShipments="loadTaskListInfo" :cashCollected="cashCollected" :confirmTaskListModal="confirmTaskListModal"  @confirmTaskListModal="confirmTaskListModal = $event"></confirm-task-list>
  </div>
</template>

<script>
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import i18nData from '../../i18n/i18nData.js'
import ActiveTaskSheets from './components/ActiveTaskSheets.vue'
import {sendRequest} from '../../http/axios/requestHelper.js'
import EditSuppliesCount from './components/EditSuppliesCount.vue'
import TaskListOverview from './components/TaskListOverview.vue'
import MoveTaskListView from './MoveTaskList.vue'
import TaskListOrders from './components/TaskListOrders.vue'
import TaskListSupplies from './components/TaskListSupplies.vue'
import TaskListInfo from './components/TaskListInfo.vue'
import ConfirmTaskList from './components/ConfirmTaskList.vue'
import axios from 'axios'
import common from '../../assets/utils/common'
import MerchantsButton from '../manager/components/MerchantsButton.vue'

export default {
  data () {
    return {
      common,
      returnOrderAmount: 0,
      confirmTaskListModal: false,
      selected: [],
      activeTab: 0,
      activeTabDic: {'0': 'Overview', '1': 'Orders', '2': 'Supplies'},
      activeTabNameDic: {'Overview': 0, 'Orders': 1, 'Supplies': 2},
      taskListInfoData: {
        creator: {},
        customer_agent: {},
        merchant_agent: {},
        task_sheet_supplies: []
      },
      taskList: {
        creator: {},
        customer_agent: {},
        merchant_agent: {},
        task_sheet_supplies: []
      },
      createdNum: [],
      pickups: [],
      drops: [],
      dropOffsCash: 0,
      cashCollected: 0,
      cridetCardCollected: 0,
      taskSheetOrders: [],
      activeTaskFlag: false,
      taskSheetOrder: [],
      sequence: 0,
      endDayFlag: false,
      allSupplies: [],
      MATaskSheet: false,
      supplyModal: false,
      supply: {},
      returns: [],
      merchantOrders: [],
      overview: [],
      suppliesDic: {},
      completeFlag: false,
      updatedStatus: '',
      closeTask: false,
      moveTaskListModal: false,
      agentsDic: {},
      tableLoader: false,
      overviewTableLoader: false,
      agents: [],
      agentsLoaded: false,
      taskSheetType: '',
      totalRows: 0,
      offset: 0,
      currentPage: 1,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      suppliesTotalRows: 0,
      suppliesOffset: 0,
      suppliesCurrentPage: 1,
      suppliesMaximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRow: {
        pickups: {
          assigned: 0,
          inProgress: 0,
          refused: 0,
          completed: 0
        },
        returns: {
          assigned: 0,
          inProgress: 0,
          postponed: 0,
          refused: 0,
          completed: 0
        },
        rtos: {
          assigned: 0,
          inProgress: 0,
          postponed: 0,
          refused: 0,
          completed: 0
        },
        supplies: {
          assigned: 0,
          inProgress: 0,
          refused: 0,
          completed: 0
        }
      },
      orderTypeFilter: '',
      orderTypes: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Pickups',
          value: 'pickups'
        },
        {
          name: 'Returns',
          value: 'returns'
        },
        {
          name: 'RTOs',
          value: 'rto'
        }
      ],
      ordersStatus: [
        {
          name: 'All',
          value: 'all',
          type: 'pickups'
        },
        {
          name: 'All',
          value: 'all',
          type: 'returns'
        },
        {
          name: 'All',
          value: 'all',
          type: 'rto'
        },
        {
          name: 'pickup requested',
          value: 'pickup_requested',
          type: 'pickups'
        },
        {
          name: 'out for pickup',
          value: 'out_for_pickup',
          type: 'pickups'
        },
        {
          name: 'picked up',
          value: 'picked_up',
          type: 'pickups'
        },
        {
          name: 'pickup rescheduled',
          value: 'pickup_rescheduled',
          type: 'pickups'
        },
        {
          name: 'in transit',
          value: 'in_transit',
          type: 'returns'
        },
        {
          name: 'Return Postponed',
          value: 'return_postponed',
          type: 'returns'
        },
        {
          name: 'out for return',
          value: 'out_for_return',
          type: 'returns'
        },
        {
          name: 'Returned',
          value: 'returned',
          type: 'returns'
        },
        {
          name: 'in transit',
          value: 'in_transit',
          type: 'rto'
        },
        {
          name: 'Return Postponed',
          value: 'return_postponed',
          type: 'rto'
        },
        {
          name: 'Out For Return',
          value: 'out_for_return',
          type: 'rto'
        },
        {
          name: 'Returned',
          value: 'returned',
          type: 'rto'
        }
      ],
      filters: [],
      merchantsPickupPoints: [],
      merchantFilter: ''
    }
  },
  watch: {
    merchantFilter () {
      this.loadFirstMileOrders()
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push(
        {
          query: {
            ...this.$route.query,
            page: this.currentPage
          }
        }
      ).catch(() => {})
      this.loadFirstMileOrders()
    },
    suppliesCurrentPage () {
      this.suppliesOffset = (this.suppliesCurrentPage - 1) * this.maximumItems
      this.loadSupplies()
    },
    'activeTab' () {
      this.selected = []
      if (this.taskListInfoData && this.taskListInfoData.merchant_agent) {
        this.$router.push({
          query: {
            tab: this.activeTabDic[this.activeTab]
          }
        }).catch(() => {})
      }
    }
  },
  methods: {
    getstyle (status) {
      return `background: ${common.getOrderStatusColor(status)}`
    },
    getOrderStatusColor (status) {
      return common.getOrderStatusColor(status)
    },
    changeOrderStatusFilter (filterValue) {
      this.currentPage = 1
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(filterValue) ? this.filters.splice(this.filters.indexOf(filterValue), 1) : this.filters.push(filterValue)
      if (filterValue === 'all') {
        this.filters = []
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadFirstMileOrders()
    },
    changeFilter (filterValue) {
      this.filters = []
      this.orderTypeFilter = ''
      this.orderTypeFilter = filterValue
      this.currentPage = 1
      this.$router.push({
        query: {
          ...this.$route.query,
          filter: '',
          orderType: this.orderTypeFilter
        }
      }).catch(() => {})
      this.loadFirstMileOrders()
    },
    closeModal () {
      this.closeTask = false
    },
    redirectToSupplies () {
      this.closeTask = false
      this.$router.push({
        query: {
          tab: this.activeTabDic[2]
        }
      }).catch(() => {})
      this.activeTab = 2
    },
    abortDisabled () {
      if (this.taskListInfoData.merchant_agent)  {
        this.endDayFlag = this.taskListInfoData.is_ready_for_completed
      } else if (this.taskListInfoData.customer_agent) {
        const ordersStatus = this.taskSheetOrders.filter(element => element.status === 'out_for_delivery' || element.status === 'return_attempted' || element.status === 'picked_up' || element.status === 'collection_attempted' || element.status === 'collected' || element.status === 'delivered')
        if (ordersStatus.length > 0 || this.cashCollected > 0) {
          this.endDayFlag = false
        } else {
          this.endDayFlag = true
        }
      }
    },
    loadTaskListInfo () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v2/task-sheets/${this.$route.params.taskListID}/`, 'get', null, true,
        (response) => {
          this.taskListInfoData = response.data
          if (this.taskListInfoData.customer_agent) {
            this.loadShipments()
          } else {
            this.loadSupplies()
            this.loadFirstMileOrders()
          }
        }
      )
    },
    loadFirstMileOrders () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v2/task-sheets/${this.$route.params.taskListID}/orders/?offset=${this.offset}&limit=${this.maximumItems}${this.orderTypeFilter ? `&${this.orderTypeFilter}=true` : ''}&order_status=${this.filters}&merchant_point=${this.merchantFilter && this.merchantFilter.id && this.merchantFilter.id !== 0 ? this.merchantFilter.id : ''}`, 'get', null, true,
        (response) => {
          this.agentsLoaded = true
          this.selected = []
          this.drops = []
          this.taskSheetOrders = []
          this.taskList = response.data.results
          this.totalRows = response.data.count
          this.taskSheetType = this.taskListInfoData.customer_agent ? 'customer' : 'merchant'
          this.MATaskSheet = true
          this.activeTab = this.$route.query.tab ? this.activeTabNameDic[this.$route.query.tab] : '0'
          response.data.results.map(element => {
            element.order.sequence = element.sequence
            element.order.operationsStatus = element.operations_status
            this.taskSheetOrders.push(element.order)
          })
          this.taskSheetOrders.sort(function (order, secondOrder) {
            if (order.merchant.name < secondOrder.merchant.name) { return -1 }
            if (order.merchant.name > secondOrder.merchant.name) { return 1 }
            return 0
          })
          this.tableLoader = false
          this.returns = this.taskSheetOrders.filter(element => element.tracking_number[0] === '2' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '2') || element.rto_requested === true)
          this.returns.forEach(element => {
            element.merchantName = element.merchant.name
          })
          const groupBy = (xs, key) => {
            return xs.reduce((rv, x) => {
              (rv[x[key]] = rv[x[key]] || []).push(x)
              return rv
            }, {})
          }
          
          this.disableEndDay()
          this.merchantOrders = groupBy(this.returns, 'merchantName')
          this.abortDisabled()
          this.countCreatedSup()
        }
      )
    },
    loadSupplies () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v2/task-sheets/${this.$route.params.taskListID}/supplies-requests/?offset=${this.suppliesOffset}&limit=${this.maximumItems}`, 'get', null, true,
        (response) => {
          this.selected = []
          this.drops = []
          this.allSupplies = []
          response.data.results.forEach(item => {
            this.allSupplies.push(item.supplies_request)
          })
          this.suppliesTotalRows = response.data.count
          this.tableLoader = false
          this.countCreatedSup()
        }
      )
    },
    loadShipments () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/`, 'get', null, true,
        (response) => {
          this.agentsLoaded = true
          this.selected = []
          this.drops = []
          this.taskSheetOrders = []
          this.dropOffsCash = 0
          this.cashCollected = 0
          this.cridetCardCollected = 0
          this.taskList = response.data
          this.taskSheetType = this.taskListInfoData.customer_agent ? 'customer' : 'merchant'
          response.data.task_sheet.map(element => {
            if (element.order) {
              element.order.sequence = element.sequence
              element.order.operationsStatus = element.operations_status
              this.taskSheetOrders.push(element.order)
            } else {
              element.cash_collection.operationsStatus = element.operations_status
              element.cash_collection.sequence = element.sequence
              this.taskSheetOrders.push(element.cash_collection)
            }
            // All tracking_numbers are delivered except tracking_numder what started by number 2
            if (element.order && (element.order.tracking_number[0] === '1' || (element.order.tracking_number[0] === '3' && element.order.tracking_number[1] === '1'))) {
              this.drops.push(element.order)
            } else if (element.cash_collection && element.cash_collection.tracking_number[0] === '4') {
              this.drops.push(element.cash_collection)
            } else if (element.order && (element.order.tracking_number[0] === '2' || (element.order.tracking_number[0] === '3' && element.order.tracking_number[1] === '2'))) {
              this.pickups.push(element.order)
            }
          })
          if (this.taskListInfoData.status !== 'completed' || new Date(this.taskListInfoData.created).toISOString() < new Date('02/09/2022').toISOString()) {
            this.pickups.forEach(element => {
              if (element.status === 'picked_up' || element.status === 'in_transit') {
                this.cashCollected = this.cashCollected - element.cash_amount
              }
            })
            this.drops.forEach(element => {
              if ((element.tracking_number[0] === '1' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '1')) && parseInt(element.cash_amount) > 0) {
                this.dropOffsCash = this.dropOffsCash + element.cash_amount
                if (element.status === 'delivered' && element.is_paid === false) {
                  this.cashCollected = element.cash_amount + this.cashCollected
                }
              } else if (element.tracking_number[0] === '4') {
                this.dropOffsCash = this.dropOffsCash + element.amount
                if (element.status === 'collected') {
                  this.cashCollected = element.amount + this.cashCollected
                }
              }
            })
            this.cashCollected += this.taskListInfoData.custody_status === 'received_by_agent' ? Number(this.taskListInfoData.custody_from_khazna) : 0
          } else {
            this.pickups.forEach(element => {
              if (element.operationsStatus === 'completed') {
                this.cashCollected = this.cashCollected - element.cash_amount
              }
            })
            this.drops.forEach(element => {
              if ((element.tracking_number[0] === '1' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '1')) && parseInt(element.cash_amount) > 0) {
                this.dropOffsCash = this.dropOffsCash + (element.is_paid === false ? element.cash_amount : 0)
                if (element.operationsStatus === 'completed' && element.is_paid === false) {
                  this.cashCollected = element.cash_amount + this.cashCollected
                }
              } else if (element.tracking_number[0] === '4') {
                this.dropOffsCash = this.dropOffsCash + element.amount
                if (element.operationsStatus === 'completed') {
                  this.cashCollected = element.amount + this.cashCollected
                }
              }
            })
            this.cashCollected += this.taskListInfoData.custody_status === 'received_by_agent' ? Number(this.taskListInfoData.custody_from_khazna) : 0
          }
          this.taskSheetOrders.sort(function (order, secondOrder) { return order.sequence - secondOrder.sequence })
          this.tableLoader = false
          this.returnOrderAmount = 0
          this.returns = this.taskSheetOrders.filter(element => element.tracking_number[0] === '2' || (element.tracking_number[0] === '3' && element.tracking_number[1] === '2') || element.rto_requested === true)
          this.returns.forEach(element => {
            this.returnOrderAmount = this.returnOrderAmount + element.cash_amount
            element.merchantName = element.merchant.name
          })
          const groupBy = (xs, key) => {
            return xs.reduce((rv, x) => {
              (rv[x[key]] = rv[x[key]] || []).push(x)
              return rv
            }, {})
          }
          this.drops.forEach(element => {
            if (element.is_paid === true) {
              this.cridetCardCollected = element.cash_amount + this.cridetCardCollected
            }
          })
          this.disableEndDay()
          this.merchantOrders = groupBy(this.returns, 'merchantName')
          this.abortDisabled()
        }
      )
    },
    loadAgents () {
      this.$vs.loading()
      let URl = ''
      if (this.taskListInfoData.merchant_agent) {
        URl = `api/v1/warehouse/${this.$route.params.warehouseID}/agents/merchant_agent/?limit=100`
      } else {
        URl = `api/v1/warehouse/${this.$route.params.warehouseID}/agents/customer_agent/?limit=100`
      }
      sendRequest(false, false, this, URl, 'get', null, true,
        (response) => {
          this.agents = response.data.results
          this.agents.forEach(element => {
            this.agentsDic[element.id] = element
            element.fullName = `${element.first_name} ${element.last_name}`
          })
          if (this.$route.query.type) {
            this.taskList.assigned_to = this.agentsDic[this.$route.query.agent]
          }
        }
      )
    },
    countCreatedSup () {
      this.createdNum = this.taskList.task_sheet_supplies.filter(item => item.supplies_request.status === 'created' || item.supplies_request.status === 'out_for_delivery')
    },
    changeSupplieStatus () {
      this.createdNum.forEach(item => {
        this.confirmDeliveredSupply(item.supplies_request.id)
      })
      this.closeTask = false
    },
    loadOverview () {
      this.overviewTableLoader = true
      sendRequest(true, false, this, `api/v2/task-sheets/${this.$route.params.taskListID}/overviews/`, 'get', null, true,
        (response) => {
          response.data.forEach(item => {
            this.merchantsPickupPoints.push({id: item.merchant_point.id, name: `${item.merchant.name  } - ${  item.merchant_point.address.zone.name}`})
            this.totalRow = {
              pickups: {
                assigned: this.totalRow.pickups.assigned + item.pickups_total,
                inProgress: this.totalRow.pickups.inProgress + item.in_progress_delivery_pickups,
                refused: this.totalRow.pickups.refused + item.delivery_pickups_postponed,
                completed: this.totalRow.pickups.completed + item.successful_delivery_pickups
              },
              returns: {
                assigned: this.totalRow.returns.assigned + item.returns_total,
                inProgress: this.totalRow.returns.inProgress + item.in_progress_returns,
                postponed: this.totalRow.returns.postponed + item.postponed_returns,
                refused: this.totalRow.returns.refused + item.failed_returns,
                completed: this.totalRow.returns.completed + item.successful_returns
              },
              rtos: {
                assigned: this.totalRow.rtos.assigned + item.rtos_total,
                inProgress: this.totalRow.rtos.inProgress + item.in_progress_rtos,
                postponed: this.totalRow.rtos.postponed + item.postponed_rtos,
                refused: this.totalRow.rtos.refused + item.failed_rtos,
                completed: this.totalRow.rtos.completed + item.successful_rtos
              },
              supplies: {
                assigned: this.totalRow.supplies.assigned + item.supplies_total,
                inProgress: this.totalRow.supplies.inProgress + item.in_progress_supplies,
                refused: this.totalRow.supplies.refused + item.failed_supplies,
                completed: this.totalRow.supplies.completed + item.successful_supplies
              }
            }
          })
          this.merchantsPickupPoints.unshift({name: 'All', id: 0})
          this.overview = response.data
          this.overviewTableLoader = false
        }
      )
    },
    confirmUpdateTaskListStatus (status) {
      this.updatedStatus = status
      this.$vs.dialog({
        color: 'success',
        title: 'Confirm',
        text: `Are you sure to ${status.split('ed')[0]} this task list?`,
        accept: this.updateTaskListStatus
      })
    },
    updateTaskListStatus () {
      const API = this.updatedStatus === 'failed' ? `api/v2/task-sheets/${this.$route.params.taskListID}/confirm-failed/` : `api/v1/task-sheets/${this.$route.params.taskListID}/`
      const method = this.updatedStatus === 'failed' ? 'post' : 'patch'
      sendRequest(false, false, this, API, method, {status: this.updatedStatus}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Task List'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.taskListInfoData.status = this.updatedStatus
          this.loadShipments()
        }
      )
    },
    openMoveOrdersModel (selected) {
      this.activeTaskFlag = true
      this.taskSheetOrder = selected
      this.sequence = this.taskSheetOrders.length > 0 ? this.taskSheetOrders[this.taskSheetOrders.length - 1].sequence : 0
    },
    addOrders () {
      this.$router.push({
        name: 'head-of-fleet-add-orders-to-task-list',
        params: {
          warehouseID: this.$route.params.warehouseID,
          taskListID: this.$route.params.taskListID
        },
        query: {
          type: this.taskListInfoData.customer_agent ? 'CA' : 'MA',
          agent: this.taskListInfoData.customer_agent ? this.taskListInfoData.customer_agent.id : this.taskListInfoData.merchant_agent.id,
          sequence: this.taskSheetOrders.length > 0 ?  this.taskSheetOrders[this.taskSheetOrders.length - 1].sequence : 0
        }
      }).catch(() => {})
    },
    printTaskList () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#print-btn-loading',
        scale: 0.45
      })
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v2/task-sheets/${this.$route.params.taskListID}/print-returns/`,
        method: 'POST',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Returns POD.pdf')
        document.body.appendChild(link)
        link.click()
        this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
      }).catch((error) => {
        if (error && error.response && error.response.status === 400 && error.response.status) {
          this.$vs.notify({
            color:'danger',
            title: i18nData[this.$i18n.locale]['Failed'],
            text: i18nData[this.$i18n.locale]['There is no return orders in this task sheet.'],
            position: 'top-center'
          })
        }
        this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
      })
    },
    disableEndDay () {
      if (this.taskListInfoData.customer_agent) {
        const ordersStatus = this.taskSheetOrders.map(elements => elements.status === 'in_transit' || elements.status === 'cancelled' || elements.status === 'delivered' || elements.status === 'collection_attempted' || elements.status === 'collected' || elements.status === 'return_requested' || elements.status === 'return_attempted' || elements.status === 'returned' || elements.status === 'lost' || elements.status === 'damaged')
        this.completeFlag = (ordersStatus.every(status => status === true) && this.dropOffsCash === 0) || (ordersStatus.every(status => status === true))
      } else {
        this.completeFlag = this.taskListInfoData.is_ready_for_completed
      }
    },
    confirmDeliveredSupply (deliveredSupplyid) {
      let successCounter = 0
      sendRequest(false, false, this, `api/v1/supplies/${deliveredSupplyid}/`, 'patch', {status: 'delivery_attempted'}, true,
        () => {
          successCounter++
          if (this.createdNum.length === successCounter) {
            this.updatedStatus = 'completed'
            this.updateTaskListStatus()
          }
        }
      )
    },
    openTaskListViewModal (selected) {
      this.loadAgents()
      this.moveTaskListModal = true
      this.taskSheetOrder = selected
      this.sequence = this.taskSheetOrders.length > 0 ? this.taskSheetOrders[this.taskSheetOrders.length - 1].sequence : 0
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    ActiveTaskSheets,
    EditSuppliesCount,
    TaskListOverview,
    ShipbluPrompt,
    MoveTaskListView,
    TaskListOrders,
    TaskListSupplies,
    TaskListInfo,
    ConfirmTaskList,
    MerchantsButton
  },
  created () {
    this.filters = []
    this.ordertypefilter = ''
    this.loadOverview()
    this.loadTaskListInfo()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.received {
  color: #429f21;
  pointer-events: none;
}
.link {
  text-decoration: underline;
  color: #bfafaf
}
.con-vs-dropdown--menu{
  width: max-content;
}
.vs-con-input-label.is-label-placeholder {
  margin-top: 10px;
}
.dropdown {
  .vs-dropdown--item-link span {
    color: #0A3266 !important;
  }
}
.disabled-style{
  background-color: #C8C8C8 !important;
}
.print-label {
  display: none;
}
@media print {
  .noprint, .vs-sidebar, .vs-sidebar-primary, .vs-sidebar-parent, .vs-sidebar-reduceNotRebound {
    display: none;
  }
  .print-label {
    display: initial;
  }
}
.main {
  background: rgba(220, 238, 255, 0.4);
  border-radius: 6px;
  padding: 8px;
}
</style>
