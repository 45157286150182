<template>
  <div class="my-2">
    <template v-if="selectedFlag">
      <div class="cursor-pointer">
        <div class="available-action flex px-4 py-2" @click="downloadShipments()">
          <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm ml-2">{{ $t('Download') }} {{ `(${selected.length})` }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select order you want to download!')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none unavailable-action flex px-4 py-2">
            <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
            <span class="text-sm ml-2">{{ $t('Download') }}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
  </div>
</template>

<script>
export default {
  props: ['selected'],
  data () {
    return {
      headerTitle: ['Tracking Number', 'Pickup Date', 'Merchant Name', 'Customer Name', 'Customer Phone', 'Customer Zone', 'Customer City', 'CoD', 'Estimated Date', 'Status'],
      headerVal: ['tracking_number', 'pickup_date', 'merchant', 'customer', 'phone', 'customerZone', 'customerCity', 'cash_amount', 'estimated_date', 'status'],
      selectedFlag: false
    }
  },
  watch: {
    selected (val) {
      if (this.$store.state.AppActiveUser.userRole === 'merchant') {
        this.headerTitle.splice(2, 1)
        this.headerVal.splice(2, 1)
      }
      if (val.length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    downloadShipments () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = this.selected
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: 'Shipments',
          autoWidth: true
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        switch (j) {
        case 'pickup_date':
          return v[j] ? new Date(v[j]).toLocaleDateString('fr-CA') : ''
        case 'estimated_date':
          return v[j] ? new Date(v[j]).toLocaleDateString('fr-CA') : ''
        case 'customer':
          return `${v[j].full_name}`
        case 'phone':
          j = 'customer'
          return `${v[j].phone}`
        case 'merchant':
          return `${v[j].name}`
        case 'customerCity':
          j = 'customer'
          return `${v[j].address.zone.city.name}`
        case 'customerZone':
          j = 'customer'
          return `${v[j].address.zone.name}`
        case 'cash_processing':
          if (v[j]) return 'Yes'
          else return 'No'
        }
        return v[j]
      }))
    }
  }
}
</script>