import { render, staticRenderFns } from "./AddCashCollection.vue?vue&type=template&id=1c5bd2fb&scoped=true"
import script from "./AddCashCollection.vue?vue&type=script&lang=js"
export * from "./AddCashCollection.vue?vue&type=script&lang=js"
import style0 from "./AddCashCollection.vue?vue&type=style&index=0&id=1c5bd2fb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c5bd2fb",
  null
  
)

export default component.exports