<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="payoutModal"
    :title="$t('Add Payout')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="vx-col w-1/2 mb-10">
      <v-select v-validate="'required'" class="mt-10 w-full" name="type" v-model="payout.payoutType" :placeholder="$t('Type')" label="name" :options="types"/>
      <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
    </div>
    <div class="vx-row" v-if="payout.payoutType && payout.payoutType.value === 'bank'">
      <div class="vx-col" :class="payout.bank ? 'w-1/2' : 'w-full'">
        <v-select v-validate="'required'" class="mt-8 w-full" name="bank name" v-model="payout.bank" :placeholder="$t('Bank Name')" label="name" :options="banks"/>
        <span class="text-danger text-sm" v-show="errors.has('bank name')">{{ errors.first('bank name') }}</span>
      </div>
      <div v-if="payout.bank" class="vx-col w-1/2">
        <vs-input disabled @cut.prevent @copy.prevent @paste.prevent name="swift code" :label-placeholder="$t('Swift Code') + '*'" v-model="payout.bank.swift" class="mt-8 w-full"/>
      </div>
      <div class="vx-col w-full">
        <vs-input ref="account number" @cut.prevent @copy.prevent @paste.prevent v-validate="'required'" name="account name" :label-placeholder="$t('Account Name') + '*'" v-model="payout.creditor_name" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('account name')">{{ errors.first('account name') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input ref="account number" @cut.prevent @copy.prevent @paste.prevent v-validate="'required|alpha_num|min:6'" name="account number" :label-placeholder="$t('Account Number') + '*'" v-model="payout.account_number" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('account number')">{{ errors.first('account number') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input @cut.prevent @copy.prevent @paste.prevent v-validate="{ required: true, confirmed:'account number'}" name="confirm account number" :label-placeholder="$t('Confirm Account Number') + '*'" v-model="payout.confirm_account_number" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('confirm account number')">{{ errors.first('confirm account number') }}</span>
      </div>
    </div>
    <div v-else-if="payout.payoutType && payout.payoutType.value === 'wallet'" class="vx-row">
      <div class="vx-col w-1/2">
        <vs-input ref="mobile wallet" @cut.prevent @copy.prevent @paste.prevent v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="mobile wallet" :label-placeholder="$t('Mobile Wallet') + '*'" v-model="payout.number" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('mobile wallet')">{{ errors.first('mobile wallet') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input @cut.prevent @copy.prevent @paste.prevent v-validate="{ required: true, confirmed:'mobile wallet', regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="confirm mobile wallet" :label-placeholder="$t('Confirm Mobile Wallet') + '*'" v-model="payout.confirm_account_number" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('confirm mobile wallet')">{{ errors.first('confirm mobile wallet') }}</span>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-4">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="pinOpen" class="active-btn btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import i18nData from '../../../i18n/i18nData.js'

export default {
  props: ['payoutModal', 'payout', 'pinModal'],
  data () {
    return {
      types: [],
      banks: [
        {
          'name': 'البنك العربى الافريقى الدولى (AAIB)',
          'swift': 'ARAIEGCXXXX'
        },
        {
          'name': 'بنك المؤسسة العربية المصرفية – مصر (ABC)',
          'swift': 'EAABEGCX'
        },
        {
          'name': 'بنك الأهلي الكويتي - مصر (ABK)',
          'swift': 'ECBAEGCAXXX'
        },
        {
          'name': 'بنك البركة - مصر (ABRK)',
          'swift': 'ABRKEGCAXXX'
        },
        {
          'name': 'بنك أبو ظبى الاسلامى - مصر (ADIB)',
          'swift': 'ABDIEGCAXXX'
        },
        {
          'name': 'بنك الإستثمار العربي (AIB)',
          'swift': 'AINBEGCAXXX'
        },
        {
          'name': 'البنك العربى (ARAB)',
          'swift': 'ARABEGCXXXX'
        },
        {
          'name': 'المصرف العربي الدولي (ARIB)',
          'swift': 'ARIBEGCXXXX'
        },
        {
          'name': 'البنك الأهلي المتحد (AUB)',
          'swift': 'DEIBEGCXXXX'
        },
        {
          'name': 'بنك عَوده (AUDI)',
          'swift': 'AUDBEGCAXXX'
        },
        {
          'name': 'التجاري وفابنك إيجيبت ش.م.م (BBE)',
          'swift': 'BCBIEGCXXXX'
        },
        {
          'name': 'بنك القاهرة (BDC)',
          'swift': 'BCAIEGCXXXX'
        },
        {
          'name': 'بنك بلوم - مصر (BLOM)',
          'swift': 'MRBAEGCXXXX'
        },
        {
          'name': 'بنك الأسكندرية (BOA)',
          'swift': 'ALEXEGCXXXX'
        },
        {
          'name': 'بنك كريدى أجريكول (CAE)',
          'swift': 'AGRIEGCXXXX'
        },
        {
          'name': 'البنك المركزي المصري (CBE)',
          'swift': 'CBEGEGCXXXX'
        },
        {
          'name': 'البنك التجارى الدولى (CIB)',
          'swift': 'CIBEEGCXXXX'
        },
        {
          'name': 'سيتي بنك ان ايه - مصر (CITI)',
          'swift': 'CITIEGCXXXX'
        },
        {
          'name': 'البنك العقارى المصرى العربى (EALB)',
          'swift': 'ARLBEGCAXXX'
        },
        {
          'name': 'البنك المصرى لتنمية الصادرات (EDBE)',
          'swift': 'EXDEEGCXXXX'
        },
        {
          'name': 'البنك المصرى الخليجى (EGB)',
          'swift': 'EGGBEGCAXXX'
        },
        {
          'name': 'بنك الإمارات دبي الوطنى (ENBD)',
          'swift': 'EBILEGCXXXX'
        },
        {
          'name': 'بنك ابو ظبي الأول (FAB)',
          'swift': 'NBADEGCAXXX'
        },
        {
          'name': 'بنك فيصل الإسلامى المصرى (FAIB)',
          'swift': 'FIEGEGCXXXX'
        },
        {
          'name': 'بنك التعمير والإسكان (HDB)',
          'swift': 'HDBKEGCAXXX'
        },
        {
          'name': 'بنك اتش اس بى سى مصر (HSBC)',
          'swift': 'EBBKEGCXXXX'
        },
        {
          'name': 'بنك التنمية الصناعية (IDB)',
          'swift': 'DIBBEGCAXXX'
        },
        {
          'name': 'بنك المشرق (MASH)',
          'swift': 'MSHQEGCAXXX'
        },
        {
          'name': 'ميد بنك (MIDB)',
          'swift': 'MIDBEGCXXXX'
        },
        {
          'name': 'بنك مصر (MISR)',
          'swift': 'BMISEGCXXXX'
        },
        {
          'name': 'البنك الأهلي المصري (NBE)',
          'swift': 'NBEGEGCXXXX'
        },
        {
          'name': 'البنك الأهلي اليوناني (NBG)',
          'swift': 'ETHNEGCAXXX'
        },
        {
          'name': 'بنك الكويت الوطنى - مصر (NBK)',
          'swift': 'WABAEGCXXXX'
        },
        {
          'name': 'بنك ناصر الاجتماعي (NSB)',
          'swift': 'NSOBEGC1XXX'
        },
        {
          'name': 'البنك الزراعي المصري (PDAC)',
          'swift': 'BDACEGCAXXX'
        },
        {
          'name': 'البريد المصري (POST)',
          'swift': 'PSTEEGCXXXX'
        },
        {
          'name': 'بنك قطر الوطنى الأهلى (QNB)',
          'swift': 'QNBAEGCXXXX'
        },
        {
          'name': 'بنك الشركة العربية المصرفية الدولية (SAIB)',
          'swift': 'SBNKEGCXXXX'
        },
        {
          'name': 'بنك قناة السويس (SCB)',
          'swift': 'SUCAEGCXXXX'
        },
        {
          'name': 'المصرف المتحد(UB)',
          'swift': 'UBOEEGCXXXX'
        },
        {
          'name': 'بنك ابو ظبى التجارى – مصر ش.م .م (ADCB)',
          'swift': 'UNBEEGCXXXX'
        }
      ]
    }
  },
  components: {
    vSelect,
    ShipbluPrompt
  },
  watch: {
    payoutModal (val) {
      this.types = []
      if (val === true) {
        this.types.push(
          {
            name: this.$i18n.locale === 'en' ? 'Bank Account' : 'حساب بنكي',
            value: 'bank'
          },
          {
            name: this.$i18n.locale === 'en' ? 'Mobile Wallet' : 'محفظة الهاتف المحمول',
            value: 'wallet'
          }
        )
      }
    },
    'payout.payoutType' () {
      this.$validator.validate()
    }
  },
  methods: {
    closeModal () {
      this.$emit('payoutModal', false)
      this.$emit('pinModal', false)
      this.$emit('loadPayments')
    },
    pinOpen () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.sendOTP()
          this.$emit('pinModal', true)
        }
      })
    },
    sendOTP () {
      sendRequest(false, false, this, 'api/v1/accounting/payout-methods/otp/', 'post', {}, true,
        (response) => {
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
        }
      )
    }
  }
}
</script>