<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Merchants')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales'">
        <div class="flex justify-between">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button
              @click="newMerchant()"
              color="primary"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
            >
            </vs-button>
          </span>
        </div>
      </div>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="merchants"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Account Number')}}</shipblu-th>
        <shipblu-th>{{$t('Store URL')}}</shipblu-th>
        <shipblu-th>{{$t('CoD Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Wallet Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Service fees & Refunds due')}}</shipblu-th>
        <shipblu-th>{{$t('Net cash balance')}}</shipblu-th>
        <shipblu-th>{{$t('Quickbooks last updated')}}</shipblu-th>
        <shipblu-th>{{$t('Store Phone')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-merchant-view`, params: { merchantID: data[indextr].id, tab: 'info' }}">
          <shipblu-td :data="data[indextr].name">
            {{ (data[indextr].name !== data[indextr].display_name && data[indextr].display_name !== null) ? data[indextr].name + ' - ' + data[indextr].display_name : data[indextr].name}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].id">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].store_url">
            {{ data[indextr].store_url }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].codBalance">
            {{ Number(data[indextr].quickbooks_cod_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].walletBalance">
            {{ Number(data[indextr].quickbooks_wallet_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].fees">
            {{ Number(data[indextr].quickbooks_customer_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].netCash">
            {{ Number(data[indextr].quickbooks_cod_balance - data[indextr].quickbooks_customer_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].fees">
            {{ data[indextr].quickbooks_last_updated ? new Date(data[indextr].quickbooks_last_updated).toLocaleDateString('fr-CA') + ' ' + new Date(data[indextr].quickbooks_last_updated).toLocaleTimeString('en-GB') : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].store_phone">
            {{ data[indextr].store_phone }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ data[indextr].address.zone ? $t(data[indextr].address.zone.name) : 'N/A' }} / {{ data[indextr].address.zone ? $t(data[indextr].address.zone.city.name) : 'N/A' }}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <user :userModal="userModal" @userModal="userModal = $event" @loadMerchants="loadMerchants"></user>
  </div>
</template>

<script>
import User from './components/User.vue'
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      merchantURL: 'api/v1/merchants/',
      selected: [],
      merchants: [],
      userModal: false,
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' '
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push(
        {
          name: `${this.$store.state.AppActiveUser.userRole}-accounts-merchants`,
          query: {
            page: this.currentPage
          }
        }
      ).catch(() => {})
      this.loadMerchants()
    }
  },
  methods: {
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantsSearch)
    },
    loadMerchantsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadMerchants()
    },
    loadMerchants () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      sendRequest(true, false, this, `${this.merchantURL}${query}`, 'get', null, true,
        (response) => {
          this.merchants = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    viewMerchant (data) {
      this.$router.push(
        {
          name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
          params: {
            merchantID: data.id,
            tab: 'info'
          }
        }
      ).catch(() => {})
    },
    newMerchant () {
      this.userModal = true
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadMerchants()
  },
  components: {
    User,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
