<template>
  <div>
    <!-- Header -->
    <div class="flex md:flex-row flex-col justify-between xl:gap-4 md:gap-x-6 gap-4 md:items-center xl:h-34">
      <div class="xl:w-full lg:w-1/2 relative">
        <div class="w-full font-semibold xl:pl-10 lg:pl-2 md:whitespace-no-wrap">
          <p class="text-2xl text-dark">{{$t('Welcome')}} <span class="text-primary">{{merchant.name}}</span> {{$t('Team,')}}</p>
          <p class="text-dark text-lg">{{$t('Check out your year in parcels moves 2023.')}}</p>
          <p class="text-darkgray text-sm mt-1">{{$t('*Data based on period from 1 Jan 2023 to 31 Dec 2023')}}</p>
        </div>
        <img class="xl:block hidden h-64 max-w-full absolute left-0 bottom-0 filp-image" style="z-index: -1; top: -50px;" src="@/assets/images/pages/year-in-review/growth-banner-bg.svg" alt="">
      </div>
      <div class="flex gap-2 items-center justify-between">
        <div>
          <p class="text-xs font-semibold text-primary">{{$t('Delivering Growth')}}</p>
          <p class="font-bold lg:text-2xl text-lg text-grey-dark">{{$t('A Look Back at Your')}} <span class="text-primary">2023</span> {{$t('Shipping Success.')}}</p>
          <p></p>
        </div>
        <img class="w-36" src="../../../assets/images/pages/year-in-review/growth-img.svg" alt="">
      </div>
    </div>

    <!-- Delivery Performance -->
    <shipblu-card :cardLoading="performanceLoader" class="sm:p-6 p-4 mt-4">
      <merchant-performance title="Delivery Performance" chartTitle="Shipping Volume Trend" :data="deliveryPerformance" :trend="shippingTrend"></merchant-performance>
    </shipblu-card>

    <!-- Financial Performance -->
    <shipblu-card :cardLoading="performanceLoader" class="sm:p-6 p-4 mt-4">
      <merchant-performance title="Financial Performance" chartTitle="Payout Trend" :data="financialPerformance" :trend="payoutTrend"></merchant-performance>
    </shipblu-card>

    <shipblu-card class="sm:p-6 p-4 mt-4">
      <p class="font-bold sm:text-2xl text-xl text-grey-dark">{{$t('2023: We are making')}} <span class="text-primary">{{$t('shipping')}}</span> {{$t('easier')}}</p>
      <div class="mt-12 sm:p-2">
        <!-- ShipBlu Upfront -->
        <div class="flex lg:flex-row flex-col gap-6 sm:pb-10 pb-6 sm:px-4 sm:mb-10 mb-6 border border-dashed border-primary border-t-0 border-r-0 border-l-0">
          <img style="max-width: 300px;" class="flex-shrink-0 md:w-full w-64 m-auto" src="@/assets/images/pages/year-in-review/shipblu-upfront.svg" alt="">
          <div class="w-full">
            <p class="font-semibold sm:text-2xl text-xl text-blue-900">{{$t('Ship')}}<span class="text-primary">{{$t('Blu Upfront')}}</span></p>
            <p class="font-medium sm:text-xl text-dark sm:mt-4 mt-2">{{$t('Recognizing the significant challenges posed by delayed payments from Cash on Delivery (COD) orders.')}}</p>
            <ul class="pl-6 font-medium sm:text-xl text-primary sm:mt-6 mt-3">
              <li>{{$t('Enables receiving COD payments the day after pickup.')}}</li>
              <li>{{$t('Improves cash flow management.')}}</li>
              <li>{{$t('Increases investment in growth opportunities.')}}</li>
              <li>{{$t('Enhances effective inventory management.')}}</li>
            </ul>
          </div>
        </div>

        <!-- ShipBlu Secure -->
        <div class="flex lg:flex-row flex-col gap-6 sm:pb-10 pb-6 sm:px-4 sm:mb-10 mb-6 border border-dashed border-primary border-t-0 border-r-0 border-l-0">
          <img style="max-width: 300px;" class="lg:order-2 flex-shrink-0 md:w-full w-64 m-auto" src="@/assets/images/pages/year-in-review/shipblu-secure.svg" alt="">
          <div class="w-full lg:order-1">
            <p class="font-semibold sm:text-2xl text-xl text-blue-900">{{$t('Ship')}}<span class="text-primary">{{$t('Blu Secure')}}</span></p>
            <p class="font-medium sm:text-xl text-dark sm:mt-4 mt-2">{{$t('The traditional proof-of-delivery process has been inconvenient and insecure.')}}</p>
            <ul class="pl-6 font-medium sm:text-xl text-primary sm:mt-6 mt-3">
              <li>{{$t('Unique QR code sent to customers’ phones on the day of delivery.')}}</li>
              <li>{{$t('Ensures a seamless and secure delivery or exchange.')}}</li>
              <li>{{$t('Provides peace of mind for both you and your customers.')}}</li>
            </ul>
          </div>
        </div>

        <!-- ShipBlu Verify -->
        <div class="flex lg:flex-row flex-col gap-6 sm:pb-10 pb-6 sm:px-4 sm:mb-10 mb-6 border border-dashed border-primary border-t-0 border-r-0 border-l-0">
          <img style="max-width: 300px;" class="flex-shrink-0 md:w-full w-64 m-auto" src="@/assets/images/pages/year-in-review/shipblu-verify.svg" alt="">
          <div class="w-full">
            <p class="font-semibold sm:text-2xl text-xl text-blue-900">{{$t('Ship')}}<span class="text-primary">{{$t('Blu Verify')}}</span></p>
            <p class="font-medium sm:text-xl text-dark sm:mt-4 mt-2">{{$t('Addressing the challenge of fake delivery updates and the need for increased human intervention to validate.')}}</p>
            <ul class="pl-6 font-medium sm:text-xl text-primary sm:mt-6 mt-3">
              <li>{{$t('Offers a robust verification for each delivery attempt.')}}</li>
              <li>{{$t('Ensures confidence in delivery legitimacy.')}}</li>
              <li>{{$t('Reduces the need for increased human intervention.')}}</li>
            </ul>
          </div>
        </div>

        <!-- ShipBlu Shield -->
        <div class="flex lg:flex-row flex-col gap-6 sm:px-4">
          <img style="max-width: 300px;" class="lg:order-2 flex-shrink-0 md:w-full w-64 m-auto" src="@/assets/images/pages/year-in-review/shipblu-shields.svg" alt="">
          <div class="w-full lg:order-1">
            <p class="font-semibold sm:text-2xl text-xl text-blue-900">{{$t('Ship')}}<span class="text-primary">{{$t('Blu Shield')}}</span></p>
            <p class="font-medium sm:text-xl text-dark sm:mt-4 mt-2">{{$t('Dealing with the complexity of shipping high-value items due to numerous human interventions has been a challenge.')}}</p>
            <ul class="pl-6 font-medium sm:text-xl text-primary sm:mt-6 mt-3">
              <li>{{$t('Increases reimbursable amount for damage or loss.')}}</li>
              <li>{{$t('Provides flexibility and control over coverage plans.')}}</li>
            </ul>
          </div>
        </div>

        <!-- myBlu -->
        <div class="sm:mt-12 mt-10 rounded-lg md:p-10 p-4 flex lg:flex-row flex-col gap-4 items-center myBlu-bg" :style="{backgroundImage: `url(${myBluBg})`}">
          <img class="lg:order-2 flex-shrink-0 lg:w-auto md:w-64 w-52 max-w-full m-auto" src="@/assets/images/pages/year-in-review/myblu.svg" alt="">
          <div class="w-full text-white lg:order-1">
            <img class="md:w-auto w-32" src="@/assets/images/pages/year-in-review/myblu-logo.svg" alt="">
            <p class="font-semibold lg:text-4xl sm:text-3xl text-2xl">{{$t('No Cash? No Worries 😉')}}</p>
            <p class="font-bold lg:text-6xl sm:text-5xl text-4xl">{{$t('Card payment now available upon delivery')}}</p>
            <p class="font-semibold lg:text-4xl sm:text-3xl text-2xl">{{$t('Secure, Convenient, and Cash-Free')}}</p>
            <div class="flex gap-4 md:mt-20 mt-10">
              <a href="https://play.google.com/store/apps/details?id=com.shipblu.myblu" target="_blank">
                <img class="sm:w-auto w-full max-w-full cursor-pointer" src="@/assets/images/pages/year-in-review/google-play.svg" alt="">
              </a>
              <a href="https://apps.apple.com/eg/app/myblu/id6469455066" target="_blank">
                <img class="sm:w-auto w-full max-w-full cursor-pointer" src="@/assets/images/pages/year-in-review/app-store.svg" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </shipblu-card>

    <!-- Footer -->
    <div class="mt-10 font-semibold md:text-2xl text-dark md:px-12 px-4">
      <p class="text-center">{{$t('Thank you for choosing us as your eCommerce Shipping partner. Let\'s surpass these numbers together in')}} <span class="text-primary">{{'2024!'}}</span></p>
      <p class="text-right px-4 md:mt-6 mt-3">- <span class="text-blue-900">{{'Ship'}}</span><span class="text-primary">{{'Blu'}}</span> {{'Team'}}</p>
    </div>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import myBluBg from '@/assets/images/pages/year-in-review/myblu-bg.svg'
import MerchantPerformance from './MerchantPerformance.vue'
import { sendRequest } from '@/http/axios/requestHelper'
import common from '@/assets/utils/common'
import i18nData from '@/i18n/i18nData.js'

export default {
  data () {
    return {
      myBluBg,
      performanceLoader: false,
      deliveryPerformance: [],
      shippingTrend: [],
      financialPerformance: [],
      payoutTrend: [],
      merchant: ''
    }
  },
  methods: {
    loadMerchantPerformance () {
      this.performanceLoader = true
      sendRequest(true, false, this, 'api/v1/analytics/merchant/2023-review/', 'get', null, true, 
        (response) => {
          const data = response.data.stats
          this.deliveryPerformance.push(
            {
              title: 'Total Pickups',
              value: data.total_pickups.toLocaleString('en')
            },
            {
              title: 'Delivery Success Rate',
              value: `${(data.delivery_success_rate * 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}%`
            },
            {
              title: 'Avg. Delivery Time',
              value: `${data.average_delivery_time.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})} Days`
            },
            {
              title: 'Top Delivery Destination',
              value: data.top_delivery_destination !== 'None' ? i18nData[this.$i18n.locale][data.top_delivery_destination] : 'N/A',
              percentage:`${(data.top_delivery_destination_percentage * 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}% of your orders`
            }
          )
          this.financialPerformance.push(
            {
              title: 'Total COD Collected',
              sub_title: '[in EGP]',
              value: data.total_cod_collected ? data.total_cod_collected.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0
            },
            {
              title: 'Avg. Order COD',
              sub_title: '[in EGP]',
              value: data.average_order_cod.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})
            },
            {
              title: 'Avg. Days to Payout',
              value: `${data.average_days_to_payout.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})} Days`
            }
          )
          this.shippingTrend = data.shipping_volume_trend
          this.payoutTrend = data.payout_trend
          this.performanceLoader = false
        }, (error) => {
          common.notifyResponseError(this, error)
          this.performanceLoader = false
        }
      )
    }
  },
  components: {
    ShipbluCard,
    MerchantPerformance
  },
  created () {
    this.loadMerchantPerformance()
    this.merchant = JSON.parse(localStorage.getItem('merchant'))
  }
}
</script>

<style>
li {
  list-style-type: circle;
  list-style-type: disc;
}
.myBlu-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
[dir=rtl] .filp-image {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>