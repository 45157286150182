<template>
  <shipblu-prompt
    class="new-shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="shipmentModal"
    :title="$t(type)"
    :buttons-hidden="true">
    <div>
      <pickup-location :merchant="merchant" :type="type" :shipment="shipment" :pickupPoints="pickupPoints"></pickup-location>
      <customer-order-info class="mt-6 step-selector-3" :shipmentModal="shipmentModal" :resetData="resetData" :checkValidation="checkValidation" :shipment="shipment" :governorates="governorates"
        @selectionInProgress="selectionInProgress = $event" @allowToCreateCustomer="allowToCreateCustomer = $event" @resetData="resetData = $event" @loadServicesAvailability="loadServicesAvailability"></customer-order-info>
      <packages-details :resetData="resetData" @resetData="resetData = $event" :type="type" :packageSizes="packageSizes" class="mt-6 step-selector-4" :checkValidation="checkValidation" :shipment="shipment" :packages="packages" 
        @packages="packages = $event" :financialsInsurance="financialsInsurance" @allowToCreatePackage="allowToCreatePackage = $event"></packages-details>
      <extra-info :checkValidation="checkValidation" :type="type" :resetData="resetData" @allowToCreateExtra="allowToCreateExtra = $event" class="mt-6 step-selector-5" :shipment="shipment" :serviceLevels="serviceLevels"></extra-info>
    </div>
    <shipblu-card class="shadow-top md:p-4 p-2 sticky bottom-0 step-selector-6" style="z-index: 2 !important;">
      <estimated-price :shipment="shipment"></estimated-price>
      <div class="mt-3 sm:flex items-center gap-4">
        <div class="flex justify-between sm:w-1/2">
          <p class="text-lg font-medium text-black">{{$t('Shipping Fees')}}</p>
          <div class="flex items-center">
            <div class="vs-con-loading__container w-10 h-full" id="price-loading"></div>
            <p class="text-right text-primary font-semibold text-xl" name="amount">{{orderPrice.total ? Number(orderPrice.total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00' + ' EGP'}}</p>
          </div>
        </div>
        <div class="flex justify-between sm:w-1/2 gap-4 sm:mt-0 mt-2">
          <vs-button @click="cancelAdd()" class="w-1/2 font-medium md:text-lg" color="#9DADC2" type="border">{{$t('Reset')}}</vs-button>
          <vs-button class="w-1/2 font-medium md:text-lg sm:mt-0" @click="addShipment()" :class="addNewOrder ? 'pointer-events-none' : ''">{{$t('Create Order')}}</vs-button>
        </div>
      </div>
    </shipblu-card>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import CustomerOrderInfo from './add-order-view/AddCustomerOrderInfo.vue'
import PickupLocation from './add-order-view/PickupLocation.vue'
import { sendRequest } from '../../http/axios/requestHelper'
import EstimatedPrice from './add-order-view/EstimatedPrice.vue'
import ExtraInfo from './add-order-view/ExtraInfo.vue'
import PackagesDetails from './add-order-view/AddPackagesDetails.vue'
import i18nData from '../../i18n/i18nData'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'

export default {
  props: 
  [
    'shipmentModal', 'estimatePrice', 'type', 'shipment', 'governorates', 'orderPrice',
    'packageSizes', 'packages', 'serviceLevels', 'pickupPoints', 'checkValidation', 'merchant'
  ],
  data () {
    return {
      financialsInsurance:{
        insurance_package: {}
      },
      allowToCreateCustomer: false,
      allowToCreatePackage: false,
      allowToCreateExtra: false,
      resetData: false,
      searchPhoneVal: '',
      addNewOrder: false
    }
  },
  components: {
    ShipbluPrompt,
    CustomerOrderInfo,
    PickupLocation,
    EstimatedPrice,
    ExtraInfo,
    PackagesDetails,
    ShipbluCard,
    i18nData
  },
  watch: {
    'shipment.customer_governorate' () {
      if (this.canEstimatePriceDelivery(this.shipment)) {
        this.loadEstimatedPrice()
      }
    },
    'shipment.declared_value' () {
      if (this.canEstimatePriceDelivery(this.shipment)) {
        this.loadEstimatedPrice()
      }
    },
    'shipment.serviceLevel' () {
      if (this.canEstimatePriceDelivery(this.shipment)) {
        this.loadEstimatedPrice()
      }
    },
    'shipment.number_of_packages' (val) {
      if (val > 0 && this.canEstimatePriceDelivery(this.shipment)) {
        this.loadEstimatedPrice()
      }
    },
    'shipment.cash_amount' () {
      if (this.canEstimatePriceDelivery(this.shipment)) {
        this.loadEstimatedPrice()
      }
    },
    'shipment.is_customer_allowed_to_open_packages' () {
      if (this.canEstimatePriceDelivery(this.shipment)) {
        this.loadEstimatedPrice()
      }
    },
    'packages': {
      handler () {
        if (this.packages.every(packageItem => packageItem.package_size) && this.canEstimatePriceDelivery(this.shipment) && (this.type === 'Add Delivery Order' || this.type === 'Add Return')) {
          this.loadEstimatedPrice()
        }
      },
      deep: true
    },
    'shipmentModal' (val) {
      if (val === true) {
        this.parcels = [
          {
            name: 'Can\'t Open',
            checked: true
          },
          {
            name: 'Can Open',
            checked: false
          }
        ]
        this.selectionInProgress = false
        this.loadFinancialsInsurance()
        this.searchPhoneVal = ''
        this.orderProduct = {}
      }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    loadEstimatedPrice () {
      this.$vs.loading({
        color: this.colorLoading,
        container: '#price-loading',
        scale: 0.30
      })
      let type = ''
      switch (this.type) {
      case 'Add Delivery Order':
        type = 'delivery'
        break
      case 'Add Return':
        type = 'return'
        break
      case 'Add Cash Collection':
        type = 'cash-collection'
        break 
      default:
        break
      }
      const orderData = {
        to_governorate: this.shipment.customer_governorate ? this.shipment.customer_governorate.id : ''
      }
      if (type === 'return') {
        orderData.cash_amount = this.shipment.cash_amount ? Number(this.shipment.cash_amount) : 0
      } else if (type === 'delivery') {
        orderData.cash_amount = this.shipment.cash_amount ? Number(this.shipment.cash_amount) : 0
        orderData.declared_value = this.shipment.declared_value
        orderData.is_customer_allowed_to_open_packages = this.shipment.is_customer_allowed_to_open_packages
      } else {
        orderData.amount = this.shipment.cash_amount ? Number(this.shipment.cash_amount) : 0
      }
      orderData.packages = []
      if (type === 'delivery' || type === 'return') {
        orderData.packages = this.packages.map(item => item.package_size.id)
      }
      orderData.discount_code = this.shipment.promoCode
      orderData.service_level = this.shipment.serviceLevel ? this.shipment.serviceLevel.id : 0
      sendRequest(true, false, this, `api/v1/pricing/orders/${type}/`, 'post', orderData, true,
        (response) => {
          this.orderPrice = response.data
          this.$vs.loading.close('#price-loading > .con-vs-loading')
        }
      )
    },
    canEstimatePriceDelivery () {
      if (this.shipment.customer_governorate && this.packages.length > 0 && this.packages.every(packageItem => packageItem.package_size)) {
        return true
      }
    },
    cancelAdd () {
      this.shipment = {
        phone: '',
        customer_city: '',
        customer_governorate: '',
        number_of_packages: 1,
        cash_amount: 0,
        is_customer_allowed_to_open_packages: false,
        pickupPoint: '',
        is_counter_dropoff: this.merchant.is_self_signup && this.merchant.self_signup_verified === null ? 1 : 2,
        declared_value: 0,
        serviceLevel: {},
        address: {
          customerZone: '',
          line_1: '',
          zone: ''
        }
      }
      this.packages = [
        {
          description: '',
          fragile: false,
          package_size: this.packageSizes[1]
        }
      ]
      this.$emit('packages', this.packages)
      this.resetData = true
      this.serviceLevels = []
      this.$emit('shipment', this.shipment)
    },
    loadServicesAvailability () {
      this.$emit('loadServicesAvailability', this.shipment.customer_governorate.id)
    },
    addShipment () {
      this.addNewOrder = true
      this.checkValidation = !this.checkValidation
      setTimeout(() => {
        this.addNewOrder = false
        this.$validator.validateAll().then(result => {
          this.packages.length = this.orderType === 'Fulfillment' ? 1 : this.packages.length
          if (result && this.packages.length > 0 && this.allowToCreateCustomer && this.allowToCreatePackage && this.allowToCreateExtra) {
            this.$emit('addShipment')
            this.$emit('packages', this.packages)
            this.$emit('selectionInProgress', this.selectionInProgress)
            this.$emit('orderType', this.orderType)
          }
        })
      }, 100)
    },
    loadFinancialsInsurance () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchant.id}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.financialsInsurance = response.data
        }
      )
    },
    closeModal () {
      this.shipment.packages = this.packages
      localStorage.setItem('orderCreationForm', JSON.stringify(this.shipment))
      this.$emit('shipmentModal', false)
      this.phones = []
      this.$emit('totalPackages', this.packages)
      this.$emit('estimatePrice', 0)
      this.showAdditional = false
    }
  }
}
</script>

<style lang="scss">
.input-order {
  border: none;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  color: #1C5BFE;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: inherit;
  &::placeholder {
    color: #9DADC2 !important;
  }
}
.info-icon {
  position: absolute;
  top: -5px;
  right: 5px;
}
.service-level {
  label.checked-border {
    position: relative;
    input {
      position: absolute;
      left: 7px;
      top: calc(50% - 6px);
    }
    span {
      background: #FFFFFF;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
    }
  }
  .checked + span{
    color: #1C5BFE;
    background: #FFFFFF;
    font-weight: 500;
    border: 1px solid #1C5BFE;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }
}
.shadow-top {
  box-shadow: 0 -3px 10px 0 lightgray;
}
</style>