var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-6",attrs:{"id":"page-user-view"}},[_c('div',{staticClass:"vx-col flex-1",attrs:{"id":"account-info-col-1"}},[_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full lg:w-1/2 mb-base"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Merchant Invoices')))])])]),_c('shipblu-table',{attrs:{"sst":true,"multiple":"","pagination":"","max-items":_vm.maximumItems,"data":_vm.invoices,"tableLoader":_vm.tableLoader},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('shipblu-tr',{key:indextr,attrs:{"data":tr}},[_c('shipblu-td',{attrs:{"data":data[indextr].created}},[_vm._v(" "+_vm._s(new Date(data[indextr].created).toLocaleDateString('fr-CA'))+" ")]),_c('shipblu-td',{attrs:{"data":data[indextr].number}},[_vm._v(" "+_vm._s(data[indextr].number)+" ")]),_c('shipblu-td',{attrs:{"data":data[indextr].bill_to_customer_name}},[_vm._v(" "+_vm._s(data[indextr].bill_to_customer_name)+" ")]),_c('shipblu-td',{attrs:{"data":data[indextr].subtotal}},[_vm._v(" "+_vm._s(Number(data[indextr].subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}))+" ")]),_c('shipblu-td',{attrs:{"data":data[indextr].vat}},[_vm._v(" "+_vm._s(Number(data[indextr].vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}))+" ")]),_c('shipblu-td',{attrs:{"data":data[indextr].total}},[_vm._v(" "+_vm._s(Number(data[indextr].total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}))+" ")]),_c('shipblu-td',{attrs:{"data":data[indextr].order}},[((data[indextr].invoice_exchange && data[indextr].invoice_exchange.length > 0) || (data[indextr].invoice_cash_collection && data[indextr].invoice_cash_collection.length > 0) || (data[indextr].invoice_order && data[indextr].invoice_order.length > 0))?_c('router-link',{attrs:{"to":{
                name: 
                  `${_vm.$store.state.AppActiveUser.userRole}-${data[indextr].invoice_exchange.length > 0 ? 'exchanges' 
                  : data[indextr].invoice_cash_collection.length > 0 ? 'cash-collections' 
                  : data[indextr].invoice_order[0].order_type}`, 
                params: { type: `${data[indextr].invoice_exchange.length > 0 ? 'exchanges' 
                  : data[indextr].invoice_cash_collection.length > 0 ? 'cash-collections' 
                  : data[indextr].invoice_order[0].order_type}`, 
                  orderID: data[indextr].invoice_exchange.length > 0 ? data[indextr].invoice_exchange[0].exchange 
                  : data[indextr].invoice_cash_collection.length > 0 ? data[indextr].invoice_cash_collection[0].order 
                  : data[indextr].invoice_order[0].order ? data[indextr].invoice_order[0].order : ''}
              }}},[_vm._v(" "+_vm._s(_vm.$t('Open in a new tab'))+" ")]):_vm._e()],1),(_vm.$store.state.AppActiveUser.userRole !== 'sales')?_c('shipblu-td',{attrs:{"data":data[indextr].pdf_link}},[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.downloadInvoice(data[indextr])}}},[_vm._v(_vm._s(_vm.$t('Download')))])]):_vm._e()],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('div',{staticClass:"flex flex-wrap-reverse items-center flex-grow justify-between",attrs:{"slot":"header"},slot:"header"}),_c('template',{slot:"thead"},[_c('shipblu-th',[_vm._v(_vm._s(_vm.$t('Invoice Date')))]),_c('shipblu-th',[_vm._v(_vm._s(_vm.$t('Invoice Number')))]),_c('shipblu-th',[_vm._v(_vm._s(_vm.$t('Merchant Name')))]),_c('shipblu-th',[_vm._v(_vm._s(_vm.$t('Subtotal')))]),_c('shipblu-th',[_vm._v(_vm._s(_vm.$t('VAT')))]),_c('shipblu-th',[_vm._v(_vm._s(_vm.$t('Total')))]),_c('shipblu-th',[_vm._v(_vm._s(_vm.$t('Link to Order')))]),(_vm.$store.state.AppActiveUser.userRole !== 'sales')?_c('shipblu-th',[_vm._v(_vm._s(_vm.$t('Download Invoice')))]):_vm._e()],1)],2)],1)]),_c('shipblu-cursor-pagination',{attrs:{"maximumItems":_vm.maximumItems,"paginationObj":_vm.paginationObj},on:{"changePageSize":_vm.changePageSize,"changePageNumber":function($event){return _vm.changePageNumber($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }